import { twMerge } from 'tailwind-merge';

type Props = React.PropsWithChildren<{ className?: string }>;

const SVPageSubtitle = ({ className, children }: Props) => (
	<h2
		className={twMerge(
			'type-subtitle mb-4 flex justify-center text-center text-gray-400',
			className,
		)}
	>
		<span className="inline-block max-w-4xl">{children}</span>
	</h2>
);

export default SVPageSubtitle;
