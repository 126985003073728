import SVWithNoSSR from '@apps/www/src/www/containers/SVWithNoSSR';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { usePrevious } from 'react-use';

const CLOSE_MESSAGE =
	'Things are still happening on this page. You may loose pending actions if you close right now.';
// const NAVIGATE_MESSAGE =
// 	'Things are still happening on this page. You may loose pending actions if you navigate.';

const _Prompt: React.FC<{ when: boolean }> = (_props) => {
	// Prompt when navigating is not that useful, things will still be processed by the backend.
	// React.useEffect(() => {
	// 	const handleNavigateStart = () => {
	// 		if (!when) {
	// 			return;
	// 		}
	// 		if (window.confirm(NAVIGATE_MESSAGE)) {
	// 			return;
	// 		}

	// 		Router.events.emit('routeChangeError');
	// 		throw 'routeChange aborted.';
	// 	};

	// 	Router.events.on('routeChangeStart', handleNavigateStart);
	// 	return () => {
	// 		Router.events.off('routeChangeStart', handleNavigateStart);
	// 	};
	// }, [when]);

	return null;
};

const _SVPreventCloseController: React.FC = () => {
	const [lockCount, setLockCount] = useUIState(UIStateKeys.LOCK_COUNT);
	const router = useRouter();
	const previousPath = usePrevious(router.asPath);

	const isLocked = lockCount > 0;

	const handleBeforeUnload = useEventCallback(() => {
		if (isLocked) {
			return CLOSE_MESSAGE;
		}
	});

	useEffect(() => {
		if ('onbeforeunload' in window) {
			window.onbeforeunload = handleBeforeUnload;
		}

		return () => {
			if ('onbeforeunload' in window) {
				window.onbeforeunload = null;
			}
		};
	}, [handleBeforeUnload]);

	useEffect(() => {
		if (previousPath != router.asPath) {
			setLockCount(0);
		}
	}, [router.asPath, previousPath, setLockCount]);

	return <_Prompt when={isLocked} />;
};

const SVPreventCloseController = SVWithNoSSR(React.memo(_SVPreventCloseController));

export default SVPreventCloseController;
