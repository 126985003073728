import { getWindowScrollTop } from '@pkgs/shared-client/helpers/dom';
import throttle from 'lodash/throttle';
import React from 'react';
import ReactDOM from 'react-dom';
import Observer from 'react-intersection-observer';
import { createSelector } from 'reselect';

const BLEED_WINDOW_COUNT = 1; // number of window heights to render outside bounds (before and after visible window)

const sumHeights = createSelector(
	(props: Props) => ('heights' in props ? props.heights : []),
	(heights) => {
		return heights.reduce((sum, height) => sum + height, 0);
	},
);

class PaginationSensor extends React.PureComponent<{
	onPaginate: Props['onPaginate'];
}> {
	_mounted = false;

	UNSAFE_componentWillMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	handleObserverChange = (inView: boolean) => {
		if (inView && this._mounted) {
			this.props.onPaginate && this.props.onPaginate();
		}
	};

	render() {
		return (
			<Observer onChange={this.handleObserverChange}>
				<div className="absolute h-[1px]" />
			</Observer>
		);
	}
}

type Props = React.PropsWithChildren<{
	onPaginate?: (() => void) | null;
	className?: string;
	style?: React.CSSProperties;
	containerEl?: 'parent';
}> &
	({ fixedHeight: number } | { heights: number[] });

type State = {
	minIndex: number;
	minHeight: number;
	maxIndex: number;
	maxHeight: number;
	contentHeight: number;
};

class SVVirtualList extends React.Component<Props, State> {
	contentRef = React.createRef<HTMLElement>();
	lastWindowCalcScrollTop: number | null = null;
	containerEl: Window | HTMLElement | null = null;
	containerHeight = 0;
	contentOffsetTop = 0;

	constructor(props: Props) {
		super(props);

		this.state = {
			minIndex: 0,
			minHeight: 0,
			maxIndex: 0,
			maxHeight: 0,
			contentHeight: 0,
		};

		this.handleScroll = throttle(this.handleScroll, 40);
	}

	componentDidMount() {
		this.setContainerEl(this.props);

		window.addEventListener('resize', this.handleResize, true);

		this.handleResize();

		this.setContentHeight(this.props);
	}

	componentWillUnmount() {
		this.containerEl && this.containerEl.removeEventListener('scroll', this.handleScroll, true);
		window.removeEventListener('resize', this.handleResize, true);
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setContainerEl(nextProps);
		this.setContentHeight(nextProps);

		this.calcWindow(nextProps);
	}

	setContainerEl(props: Props) {
		let newContainerEl: Window | HTMLElement = window;

		if (props.containerEl === 'parent') {
			const element = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
			if (element) {
				newContainerEl = element.parentNode as HTMLElement;
			}
		}

		if (this.containerEl !== newContainerEl) {
			if (this.containerEl) {
				this.containerEl.removeEventListener('scroll', this.handleScroll, true);
			}

			if (newContainerEl) {
				this.containerEl = newContainerEl;

				this.containerEl.addEventListener('scroll', this.handleScroll, true);
			}
		}
	}

	setContentHeight(props: Props) {
		if ('heights' in props) {
			this.setState({
				contentHeight: sumHeights(props),
			});
		} else if ('fixedHeight' in props) {
			this.setState({
				contentHeight:
					props.fixedHeight * (React.Children.toArray(props.children) || []).length,
			});
		} else {
			this.setState({
				contentHeight: 0,
			});
		}
	}

	getScrollTop() {
		if (this.containerEl === window) {
			return getWindowScrollTop();
		}

		return (this.containerEl as HTMLElement).scrollTop;
	}

	calcWindow(props: Props) {
		const containerScrollTop = this.getScrollTop();
		const offset = this.containerHeight * -1 * (BLEED_WINDOW_COUNT * 2 + 1);

		const state: Partial<State> = {};
		let totalHeight = 0;

		const calcHeights = (height, i) => {
			if (
				totalHeight + height >= containerScrollTop - this.contentOffsetTop + offset &&
				totalHeight <=
					containerScrollTop - this.contentOffsetTop + this.containerHeight - offset
			) {
				if (state.minIndex === undefined || i <= state.minIndex) {
					state.minIndex = i;
					state.minHeight = totalHeight;
				}

				if (state.maxIndex === undefined || i + 1 >= state.maxIndex) {
					state.maxIndex = i + 1;
					state.maxHeight = totalHeight + height;
				}
			}

			totalHeight += height;
		};

		if ('heights' in props) {
			props.heights.forEach(calcHeights);
		} else if ('fixedHeight' in props) {
			const height = props.fixedHeight;
			(React.Children.toArray(props.children) || []).forEach((_child, i) =>
				calcHeights(height, i),
			);
		}

		if (state.minIndex === undefined) {
			state.minIndex = 0;
		}

		if (state.maxIndex === undefined) {
			state.maxIndex = 0;
		}

		if (
			this.state.minIndex !== state.minIndex ||
			this.state.maxIndex !== state.maxIndex ||
			this.state.minHeight !== state.minHeight ||
			this.state.maxHeight !== state.maxHeight
		) {
			this.setState((prevState) => ({ ...prevState, ...state }));
		}
	}

	handleResize = () => {
		if (!this.containerEl) {
			return;
		}

		if (this.containerEl === window) {
			this.containerHeight = window.innerHeight;
			this.contentOffsetTop = this.calcOffsetTop(this.contentRef.current);
		} else {
			this.containerHeight = (this.containerEl as HTMLElement).clientHeight;
			this.contentOffsetTop = this.calcOffsetTop(
				this.contentRef.current,
				this.containerEl as HTMLElement,
			);
		}

		this.lastWindowCalcScrollTop = null;

		this.handleScroll();
	};

	handleScroll = () => {
		const containerScrollTop = this.getScrollTop();

		if (
			!this.lastWindowCalcScrollTop ||
			Math.abs(this.lastWindowCalcScrollTop - containerScrollTop) >
				this.containerHeight * BLEED_WINDOW_COUNT
		) {
			this.lastWindowCalcScrollTop = containerScrollTop;

			this.calcWindow(this.props);
		}
	};

	calcOffsetTop = (el: HTMLElement | null, stopEl: HTMLElement | null = null) => {
		if (!el || (stopEl && el === stopEl)) {
			return 0;
		}

		return el.offsetTop + this.calcOffsetTop(el.offsetParent as HTMLElement, stopEl);
	};

	render = () => {
		const { minIndex, maxIndex, minHeight, maxHeight, contentHeight } = this.state;
		const { onPaginate, className, style } = this.props;

		const topSpacerStyle: React.CSSProperties = {
			position: 'relative',
			boxSizing: 'border-box',
			height: minHeight !== undefined ? minHeight : contentHeight,
		};
		const bottomSpacerStyle: React.CSSProperties = {
			position: 'relative',
			boxSizing: 'border-box',
			height: maxHeight !== undefined ? contentHeight - maxHeight : 0,
		};
		const filteredChildren = (React.Children.toArray(this.props.children) || []).slice(
			minIndex,
			maxIndex,
		);

		return (
			<div
				className={className}
				style={style}
				ref={this.contentRef as React.RefObject<HTMLDivElement>}
			>
				<div style={topSpacerStyle} />
				<div />
				{filteredChildren}
				<div style={bottomSpacerStyle} />
				{onPaginate && <PaginationSensor onPaginate={onPaginate} />}
			</div>
		);
	};
}

export default SVVirtualList;
