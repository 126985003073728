import {
	ApolloClient,
	ApolloLink,
	from,
	InMemoryCache,
	NormalizedCacheObject,
	RequestHandler,
} from '@apollo/client';
// import { fragmentCacheRedirect, fragmentLinkState } from 'apollo-link-state-fragment';
import { setContext } from '@apollo/client/link/context';
import { type TypedTypePolicies } from '@apps/www/src/__generated__/apollo-helpers';
import config from '@pkgs/shared-client/config';
import { createUploadLink } from 'apollo-upload-client';
import 'isomorphic-unfetch';
import { AppContext } from 'next/app';

// TODO: (graphql-after) perf: use fragments to avoid refetch on all queries?
// http://github.com/abhiaiyer91/apollo-fragment/tree/Elanhant/upgrade-fragment-react-to-apollo-3/packages/apollo-fragment-react

export type ApolloClientContext = {
	req: AppContext['ctx']['req'];
	res: AppContext['ctx']['res'];
};

const cacheOptions: { typePolicies: TypedTypePolicies } = {
	// cacheRedirects: {
	// 	Query: {
	// 		...fragmentCacheRedirect(),
	// 	},
	// },
	typePolicies: {
		Auth: {
			// Singleton, auth has no _id and should always be merged to the same cache key
			keyFields: [],
			merge: true,
		},
		AuthSettings: {
			merge: true,
			fields: {
				megaphones: {
					merge: false,
				},
			},
		},
		User: {
			merge: true,
			fields: {
				boards: { merge: false },
			},
		},
		Board: {
			merge: true,
			fields: {
				thumbnails: {
					merge: false,
				},
			},
		},
		BoardCollaborator: {
			merge: true,
		},
		Item: {
			merge: true,
		},
		Asset: {
			merge: true,
			fields: {
				ownBoards: {
					merge: false,
				},
			},
		},
		AssetImage: {
			merge: true,
		},
		Team: {
			merge: true,
			fields: {
				users: { merge: false },
			},
		},
	},
};

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getGlobalApolloClient = () => {
	return globalApolloClient;
};

export function initApolloClient(
	ctx: ApolloClientContext | undefined,
	initialState: AnyObject | undefined,
	getRequestHeaders: (
		ctx: ApolloClientContext | null,
	) => Promise<Record<string, string | undefined>>,
	linkMiddlewares: Array<ApolloLink | RequestHandler> = [],
	name = 'www',
): ReturnType<typeof createApolloClient> {
	const client =
		globalApolloClient ||
		createApolloClient(
			getTerminalLink(),
			ctx,
			initialState,
			getRequestHeaders,
			linkMiddlewares,
			name,
		);

	// Always return a new client on server
	if (typeof window === 'undefined') {
		return client;
	}

	// Reuse client on the client-side
	if (!globalApolloClient) {
		globalApolloClient = client;
	}

	return globalApolloClient;
}

function getTerminalLink() {
	// TODO: (graphql-after) Figure out upload progress
	// const parseHeaders = (rawHeaders) => {
	// 	const headers = new Headers();
	// 	// Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	// 	// https://tools.ietf.org/html/rfc7230#section-3.2
	// 	const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
	// 	preProcessedHeaders.split(/\r?\n/).forEach((line) => {
	// 		const parts = line.split(':');
	// 		const key = parts.shift().trim();
	// 		if (key) {
	// 			const value = parts.join(':').trim();
	// 			headers.append(key, value);
	// 		}
	// 	});
	// 	return headers;
	// };

	// const uploadFetch = (url, options) =>
	// 	new Promise((resolve, reject) => {
	// 		const xhr = new XMLHttpRequest();
	// 		xhr.onload = () => {
	// 			const opts = {
	// 				status: xhr.status,
	// 				statusText: xhr.statusText,
	// 				headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
	// 			};
	// 			opts.url =
	// 				'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
	// 			const body = 'response' in xhr ? xhr.response : xhr.responseText;
	// 			resolve(new Response(body, opts));
	// 		};
	// 		xhr.onerror = () => {
	// 			reject(new TypeError('Network request failed'));
	// 		};
	// 		xhr.ontimeout = () => {
	// 			reject(new TypeError('Network request failed'));
	// 		};
	// 		xhr.open(options.method, url, true);

	// 		Object.keys(options.headers).forEach((key) => {
	// 			xhr.setRequestHeader(key, options.headers[key]);
	// 		});

	// 		if (xhr.upload) {
	// 			xhr.upload.addEventListener('progress', options.onUploadProgress);
	// 			// xhr.upload.onprogress = options.onUploadProgress;
	// 		}

	// 		xhr.send(options.body);
	// 	});

	// const customFetch = (uri, options) => {
	// 	if (options.onUploadProgress) {
	// 		return uploadFetch(uri, options);
	// 	}

	// 	return global.fetch(uri, options);
	// };

	const uploadLink = createUploadLink({
		uri: config.graphqlURL,
		credentials: 'include',
		// fetch: customFetch,
	});

	return uploadLink;
}

export function createApolloClient(
	terminalLink: ApolloLink | RequestHandler,
	ctx: ApolloClientContext | undefined,
	initialState: AnyObject | undefined,
	getRequestHeaders: (
		ctx: ApolloClientContext | null,
	) => Promise<Record<string, string | undefined>>,
	linkMiddlewares: Array<ApolloLink | RequestHandler>,
	name: string,
) {
	let cache = new InMemoryCache(cacheOptions);

	if (initialState) {
		cache = cache.restore(initialState);
	}

	const authMiddleware = setContext(async (request, { headers = {} }) => {
		const requestHeaders = await getRequestHeaders(ctx || null);

		return {
			headers: {
				...headers,
				...requestHeaders,
			},
		};
	});

	return new ApolloClient({
		ssrMode: !(typeof window !== 'undefined'),
		link: from([
			authMiddleware,
			...linkMiddlewares,
			// fragmentLinkState(cache),
			terminalLink,
		]),
		cache,
		connectToDevTools: true,
		name,
	});
}
