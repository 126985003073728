import { useQuery } from '@apollo/client';
import SVNotificationsContainer from '@apps/www/src/www/containers/SVNotificationsContainer';
import SVGridDetailController from '@apps/www/src/www/controllers/SVGridDetailController';
import SVPreventCloseController from '@apps/www/src/www/controllers/SVPreventCloseController';
import SVPreventRightClickController from '@apps/www/src/www/controllers/SVPreventRightClickController';
import SVRouterController from '@apps/www/src/www/controllers/SVRouterController';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import createAuthUserQuery from '@apps/www/src/www/queries/createAuthUserQuery';
import { type RootState } from '@apps/www/src/www/reducers';
import { SVKeyboardContext } from '@pkgs/shared-client/components/SVKeyboardKey';
import SVLoadingIndicator from '@pkgs/shared-client/components/SVLoadingIndicator';
import config from '@pkgs/shared-client/config';
import SVWithBodyClass from '@pkgs/shared-client/containers/SVWithBodyClass';
import SVViewportController from '@pkgs/shared-client/controllers/SVViewportController';
import { formatURL } from '@pkgs/shared-client/helpers/format';
import defaultOGImage from '@pkgs/shared-client/img/default-og-image.jpg';
import React from 'react';
import { connect } from 'react-redux';
import { useUnmount } from 'react-use';
import SVBottomMarginContainer from './SVBottomMarginContainer';
import SVGridEditAreaSelectContainer from './SVGridEditAreaSelectContainer';
import SVGridEditBarContainer from './SVGridEditBarContainer';
import SVGridSettingsContainer from './SVGridSettingsContainer';
import SVGridUploadContainer from './SVGridUploadContainer';
import SVMegaphoneModalContainer from './SVMegaphoneModalContainer';
import SVNavContainer from './SVNavContainer';
import SVOnboardingModalContainer from './SVOnboardingModalContainer';
import SVPageMeta from './SVPageMeta';
import SVUpgradeWarningContainer from './SVUpgradeWarningContainer';
import SVUserHeaderContainer from './SVUserHeaderContainer';
import SVSubscriptionWarningContainer from './SVSubscriptionWarningContainer';

type Props = {
	Component: React.ComponentType<any> & {
		withNavHidden?: boolean | (({ isLoggedIn }: { isLoggedIn: boolean }) => boolean);
		withUserHeader?: boolean;
	};
	pageProps: AnyObject;
};

const _BodyClassesEmtpy = (_props: { isDarkMode: boolean }) => null;
const _BodyClasses = React.memo(
	connect((state: RootState) => ({
		isEditing: state.grid.isEditing,
		isSorting: state.grid.isSorting,
		isAreaSelecting: state.grid.isAreaSelecting,
	}))(
		SVWithBodyClass(({ isEditing, isSorting, isAreaSelecting }) => ({
			'grid-is-editing': isEditing,
			'grid-is-sorting': isSorting,
			'grid-is-area-selecting': isAreaSelecting,
		}))(
			SVWithBodyClass(
				({ isDarkMode }) => ({
					'theme-dark': isDarkMode,
					'theme-light': !isDarkMode,
					'transition-colors duration-slide ease-in-out': true,
				}),
				true,
			)(_BodyClassesEmtpy),
		),
	),
);

const _Meta = React.memo(() => {
	const meta = {
		title: true,
		description: 'The best way to save and share inspiration.',
		keywords:
			'inspiration,productivity,design tools,design,references,typography,photography,tech',
		image: {
			url: formatURL(config.staticURL, defaultOGImage),
			width: 1200,
			height: 630,
		},
	};

	return <SVPageMeta {...meta} />;
});

const _Content = ({ Component, pageProps }: Props) => {
	const [showLoading, setShowLoading] = useUIState(UIStateKeys.SHOW_LOADING);

	// Hide global loading indicator on unmount, means an error page was shown
	useUnmount(() => {
		if (showLoading) {
			setShowLoading(false);
		}
	});

	return (
		<>
			{showLoading && <SVLoadingIndicator size={SVLoadingIndicator.SIZES.PAGE} />}
			<Component {...pageProps} />
		</>
	);
};

const SVApp = ({ Component, pageProps }: Props) => {
	const [isDarkMode] = usePersistentSetting(PersistentSettingKeys.DARK_MODE);

	// Id is selected by default
	const { data, loading } = useQuery(createAuthUserQuery(['_id']));

	const isLoggedIn = Boolean(data?.auth?.user._id);

	let content: React.ReactNode = null;

	const isNavVisible =
		!Component.withNavHidden ||
		(typeof Component.withNavHidden === 'function' && !Component.withNavHidden({ isLoggedIn }));

	const isUserHeaderVisible = Component.withUserHeader;

	if (loading) {
		content = <SVLoadingIndicator size={SVLoadingIndicator.SIZES.PAGE} />;
	} else {
		content = (
			<>
				<SVViewportController />
				<SVRouterController />
				<SVPreventRightClickController />
				<SVSubscriptionWarningContainer hideOnSmallViewport />
				{isNavVisible && <SVNavContainer />}
				<SVUpgradeWarningContainer />
				<SVGridSettingsContainer />

				{isLoggedIn && <SVNotificationsContainer />}
				{isLoggedIn && <SVGridEditAreaSelectContainer />}
				{isLoggedIn && <SVGridEditBarContainer />}
				{isLoggedIn && <SVGridUploadContainer />}

				{isUserHeaderVisible && <SVUserHeaderContainer />}

				<_Content Component={Component} pageProps={pageProps} />

				<SVBottomMarginContainer isNavVisible={isNavVisible} />

				<SVGridDetailController />

				<SVMegaphoneModalContainer />
				<SVOnboardingModalContainer />
			</>
		);
	}

	return (
		<SVKeyboardContext>
			<_BodyClasses isDarkMode={isDarkMode} />
			<_Meta />
			<SVPreventCloseController />
			{content}
		</SVKeyboardContext>
	);
};

export default SVApp;
