import { ApolloLink, ApolloProvider, DefaultContext, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { type AppContext } from '@apps/www/src/pages/_app.page';
import { getAuthTokenFromClient } from '@apps/www/src/www/helpers/authToken';
import config from '@pkgs/shared-client/config';
import { ApolloClientContext, initApolloClient } from '@pkgs/shared-client/helpers/apolloClient';
import checkSupportsWebpFromClient from '@pkgs/shared-client/helpers/checkSupportsWebpFromClient';
import isNotFoundGraphQLError from '@pkgs/shared-client/helpers/isNotFoundApolloError';
import GraphQLErrorCode from '@pkgs/shared/enums/GraphQLErrorCode';
import parseCIDFromCookie from '@pkgs/shared/helpers/parseCIDFromCookie';
import parseQueryParam from '@pkgs/shared/helpers/parseQueryParam';
import type { IncomingMessage } from 'http';
import 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import Router from 'next/router';
import { useMemo } from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';

declare global {
	interface Window {
		_webpFallback: boolean;
	}
}

const showWebpFallback = async (req: Req | IncomingMessage | null = null) => {
	if (typeof window === 'undefined') {
		const { default: checkSupportsWebpFromServer } = await import(
			'@pkgs/shared-server/helpers/checkSupportsWebpFromServer'
		);
		const webpFallback = !checkSupportsWebpFromServer(req);

		return webpFallback;
	} else {
		if (typeof window._webpFallback !== 'boolean') {
			window._webpFallback = !checkSupportsWebpFromClient();
		}

		return window._webpFallback;
	}
};

const readCIDFromRequest = async (req: IncomingMessage) => {
	const { default: readCIDFromRequestImpl } = await import(
		'@pkgs/shared-server/helpers/readCIDFromRequest'
	);

	return readCIDFromRequestImpl(req);
};

const getAuthTokenFromRequest = async (req: IncomingMessage) => {
	// This is implemented here to avoid pulling server modules
	const { default: readCookieFromRequest } = await import(
		'@pkgs/shared-server/helpers/readCookieFromRequest'
	);

	let authToken: string | null | undefined = (req as any)._authToken;

	if (authToken === undefined) {
		authToken = readCookieFromRequest(req, config.authSession.tokenKey);
	}

	if (authToken === null) {
		authToken = parseQueryParam(req.headers['auth-token']);
	}

	if (authToken) {
		(req as any)._authToken = authToken;
	}

	return authToken;
};

const getRequestHeaders = async (ctx: ApolloClientContext | null) => {
	const req = ctx?.req;

	const headers: Record<string, string | undefined> = {
		'Auth-Token':
			typeof window === 'undefined'
				? req
					? await getAuthTokenFromRequest(req)
					: null
				: getAuthTokenFromClient(),
		// TODO: (abtest) standardize getting cid from cookie in the client into a function or something
		CID:
			(typeof window === 'undefined'
				? req
					? await readCIDFromRequest(req)
					: null
				: parseCIDFromCookie(Cookies.get('_ga'))) || undefined,
	};

	if (req && (await showWebpFallback(req))) {
		headers['Webp-Fallback'] = '1';
	}

	return headers;
};

let globalSetLocked: ((locked: boolean) => void) | null = null;
export const updateLockedCallback = (setLocked: (locked: boolean) => void) =>
	(globalSetLocked = setLocked);

const lockedMiddleware = new ApolloLink((operation, forward) => {
	const isMutation = operation.query.definitions.some(
		(definition) =>
			definition.kind === 'OperationDefinition' && definition.operation === 'mutation',
	);

	if (isMutation && globalSetLocked) {
		globalSetLocked(true);
	}

	return forward(operation).map((data) => {
		if (isMutation && globalSetLocked) {
			globalSetLocked(false);
		}

		return data;
	});
});

const trackingMiddleware = new ApolloLink((operation, forward) => {
	return forward(operation);
});

function redirect(ctx: DefaultContext, url: string) {
	if (typeof window !== 'undefined') {
		window.location.href = url;
	} else if ('res' in ctx && ctx.res) {
		ctx.res.writeHead(302, {
			Location: url,
		});
		ctx.res.end();
	}
}

const errorMiddleware = onError(({ graphQLErrors, operation }) => {
	const ctx = operation.getContext();

	for (const k in graphQLErrors) {
		const graphQLError = graphQLErrors[k];

		if (
			graphQLError.extensions.code === GraphQLErrorCode.REDIRECT &&
			typeof graphQLError.extensions.url === 'string'
		) {
			redirect(ctx, graphQLError.extensions.url);

			// Return an observable that never resolves to hang the request until the redirect happens
			return new Observable(() => {});
		} else if (graphQLError.extensions.code === 'UNAUTHENTICATED') {
			redirect(ctx, `${config.baseURL}login/?next=${encodeURIComponent(Router.asPath)}`);

			// Return an observable that never resolves to hang the request until the redirect happens
			return new Observable(() => {});
		} else if (
			graphQLError.extensions.exception?._name === 'ERR_FORBIDDEN' &&
			graphQLError.extensions.exception?._responseParams?.needsUpgrade &&
			graphQLError.extensions.exception?._responseParams?.upgradeURL
		) {
			redirect(
				ctx,
				`${config.baseURL}${graphQLError.extensions.exception._responseParams.upgradeURL}`,
			);

			// Return an observable that never resolves to hang the request until the redirect happens
			return new Observable(() => {});
		}
	}
});

const linkMiddlewares = [errorMiddleware, trackingMiddleware, lockedMiddleware];

const SVWithApolloApp = (AppComponent) => {
	const WrappedComponent = ({ apolloClient, initialApolloState, ...props }) => {
		if (typeof window !== 'undefined') {
			window._webpFallback = props.webpFallback;
		}

		const client = useMemo(
			() =>
				apolloClient ||
				initApolloClient(undefined, initialApolloState, getRequestHeaders, linkMiddlewares),
			[apolloClient, initialApolloState],
		);

		return (
			<ApolloProvider client={client}>
				<AppComponent {...props} />
			</ApolloProvider>
		);
	};

	WrappedComponent.displayName = wrapDisplayName(AppComponent, 'SVWithApolloApp');

	WrappedComponent.getInitialProps = async (appContext: AppContext) => {
		const { AppTree, ctx } = appContext;
		const { req, res } = ctx;

		const apolloClient = initApolloClient(
			{
				req,
				res,
			},
			undefined,
			getRequestHeaders,
			linkMiddlewares,
		);

		ctx.apolloClient = apolloClient;

		let initialAppProps: AnyObject = {};
		if (AppComponent.getInitialProps) {
			initialAppProps = await AppComponent.getInitialProps(appContext);
		}

		// Only on the server:
		if (typeof window === 'undefined' && res) {
			initialAppProps.webpFallback = await showWebpFallback();

			// When redirecting, the response is finished.
			// No point in continuing to render
			if (res && res.finished) {
				return initialAppProps;
			}

			try {
				// Run all GraphQL queries
				const { getDataFromTree } = await import('@apollo/client/react/ssr');
				// @ts-expect-error
				await getDataFromTree(<AppTree apolloClient={apolloClient} {...initialAppProps} />);
			} catch (e) {
				const error = e instanceof Error ? e : new Error('Unknown error');

				// Prevent Apollo Client GraphQL errors from crashing SSR.
				// Handle them in components via the data.error prop:
				// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error

				if ('graphQLErrors' in error && error.graphQLErrors) {
					for (const k in error.graphQLErrors) {
						const graphQLError = error.graphQLErrors[k];

						if (graphQLError.extensions.code === GraphQLErrorCode.REDIRECT) {
							redirect(ctx, graphQLError.extensions.url);

							return {};
						} else if (graphQLError.extensions.code === 'UNAUTHENTICATED') {
							redirect(
								ctx,
								`${config.baseURL}login/?next=${encodeURIComponent(
									// better safe than sorry
									ctx.asPath || (ctx as any).resolvedUrl,
								)}`,
							);

							return {};
						} else if (
							graphQLError.extensions.exception?._name === 'ERR_FORBIDDEN' &&
							graphQLError.extensions.exception?._responseParams?.needsUpgrade &&
							graphQLError.extensions.exception?._responseParams?.upgradeURL
						) {
							redirect(
								ctx,
								`${config.baseURL}${graphQLError.extensions.exception?._responseParams?.upgradeURL}`,
							);

							return {};
						}
					}
				}

				if (!isNotFoundGraphQLError(error)) {
					// eslint-disable-next-line no-console
					console.error(
						'Error while running `getDataFromTree`',
						JSON.stringify(error, null, 2),
					);
				}
			}
		}

		const initialApolloState = apolloClient.extract();

		return {
			...initialAppProps,
			initialApolloState,
		};
	};

	return WrappedComponent;
};

export default SVWithApolloApp;
