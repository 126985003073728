/* eslint-disable jsx-a11y/alt-text */
import { useApolloClient, useQuery } from '@apollo/client';
import {
	TeamFragmentFragment,
	type ItemDetailsQuery,
	type ItemFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import SVGridContainer from '@apps/www/src/www/containers/SVGridContainer';
import SVSaveItemsActionContainer from '@apps/www/src/www/containers/SVSaveItemsActionContainer';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import usePaginatedQuery from '@apps/www/src/www/hooks/usePaginatedQuery';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import RelatedItemsQuery from '@apps/www/src/www/queries/RelatedItemsQuery';
import SuggestedMentionedUsersQuery from '@apps/www/src/www/queries/SuggestedMentionedUsersQuery';
import TrackRelatedItemClickMutation from '@apps/www/src/www/queries/TrackRelatedItemClickMutation';
import SVActions from '@pkgs/shared-client/components/SVActions';
import { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVEmojiPicker from '@pkgs/shared-client/components/SVEmojiPicker';
import SVGrid from '@pkgs/shared-client/components/SVGrid';
import SVToggle from '@pkgs/shared-client/components/SVToggle';
import { preloadImage, scrollToElement } from '@pkgs/shared-client/helpers/dom';
import { formatRelativeDateShort } from '@pkgs/shared-client/helpers/format';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconArrowLeftSVG from '@pkgs/shared-client/img/arrow-left-inlined.svg';
import IconArrowRightSVG from '@pkgs/shared-client/img/arrow-right-inlined.svg';
import IcontAdd from '@pkgs/shared-client/img/icon-add-inlined.svg';
import IconBack2SVG from '@pkgs/shared-client/img/icon-back2-inlined.svg';
import IconBarsStats from '@pkgs/shared-client/img/icon-bars-stats-inlined.svg';
import IconCaretSVG from '@pkgs/shared-client/img/icon-caret-inlined.svg';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import IconEditSVG from '@pkgs/shared-client/img/icon-edit-inlined.svg';
import IconHappyFaceSVG from '@pkgs/shared-client/img/icon-happy-face-inlined.svg';
import IconMoreSVG from '@pkgs/shared-client/img/icon-more-inlined.svg';
import IconSearchSVG from '@pkgs/shared-client/img/icon-search-inlined.svg';
import { unit } from '@pkgs/shared-client/styles/mixins';
import { type ViewportNameType } from '@pkgs/shared/constants';
import AssetType from '@pkgs/shared/enums/AssetType';
import MentionsType from '@pkgs/shared/enums/MentionsType';
import colorHEXToHSV from '@pkgs/shared/helpers/colorHEXToHSV';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import Observer from 'react-intersection-observer';
import { usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';
import SVA from './SVA';
import SVAvatarsStack from './SVAvatarsStack';
import SVDropdown from './SVDropdown';
import SVFlexSpacer from './SVFlexSpacer';
import SVForm from './SVForm';
import SVIconButton from './SVIconButton';
import SVLink from './SVLink';
import SVMinimalVideoPlayer from './SVMinimalVideoPlayer';
import SVTransition from './SVTransition';
import SVImage from '@pkgs/shared-client/components/SVImage';

const _Img = ({
	itemColor,
	hide,
	...props
}: Partial<Pick<Props, 'itemColor'>> &
	Pick<React.HTMLProps<HTMLImageElement>, 'src' | 'alt' | 'onLoad'> & {
		hide?: boolean;
	}) => (
	<SVImage
		className={twMerge(
			clsx(
				'prevent-dragging absolute top-0 block h-full w-full',
				hide ? 'invisible' : 'visible',
			),
		)}
		style={{
			backgroundColor: itemColor || 'transparent',
		}}
		{...props}
	/>
);

const _Video = ({
	...props
}: Partial<Pick<Props, 'itemColor'>> &
	Pick<React.HTMLProps<HTMLVideoElement>, 'src' | 'poster'> & {
		poster: string;
	}) => <SVMinimalVideoPlayer autoPlay loop {...props} />;

const _commentOptions = (
	comment: NonNullable<Props['itemComments']>[0],
	isContent: boolean,
	handleDeleteComment: Props['handleDeleteComment'],
) => {
	const authUser = useAuthUser(['avatarURL', 'url', 'name', 'itemsCount']);

	const options = [
		...(authUser?._id === comment.user._id
			? [
					{
						label: isContent ? 'Delete Thread' : 'Delete',
						value: 'delete',
						onClick: () => handleDeleteComment(comment._id),
					},
			  ]
			: []),
		...(authUser?._id !== comment.user._id
			? [
					{
						label: 'Report',
						value: 'report',
						onClick: () => {},
					},
			  ]
			: []),
	];

	const actionOptions = (
		<>
			{options.map((option, i) => (
				<div
					key={i}
					className="text-primary cursor-pointer p-4 text-sm text-opacity-100"
					onClick={(e) => {
						e.stopPropagation();
						option.onClick();
					}}
				>
					{option.label}
				</div>
			))}
		</>
	);

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.HOVER}
			positionStrategy={SVDropdown.POSITION_STRATEGIES.FIXED}
			renderTrigger={({ isOpen: _, ...props }) => (
				<SVIconButton
					{...props}
					src={IconMoreSVG}
					iconClassName="h-4 w-4 text-gray-500 min-h-[16px] min-w-[16px]"
					onClick={(e) => e.stopPropagation()}
				/>
			)}
			renderContent={() => actionOptions}
		/>
	);
};

const _FormBallonInput = ({
	commentAdd,
	assetID,
	newCoords,
	parentComment,
	handleNewCoordsComment,
	isTeamOnly,
}: {
	commentAdd: Props['commentAdd'];
	assetID: Props['assetID'];
	newCoords?: contentTypes['newCoordsComment'];
	parentComment?: string;
	handleNewCoordsComment?: contentTypes['handleNewCoordsComment'];
	isTeamOnly: boolean;
}) => {
	const [comment, setComment] = useState<string>('');

	const handleSubmit = async () => {
		commentAdd({
			assetID: assetID ?? '',
			content: comment,
			position: newCoords || undefined,
			parentId: parentComment,
			isTeamOnly: isTeamOnly,
		});
		newCoords && handleNewCoordsComment && handleNewCoordsComment(null);
		setComment('');
	};

	return (
		<div onClick={(e) => e.stopPropagation()} className="w-full pb-1">
			<SVForm
				onSubmit={() => handleSubmit()}
				submitLabel="Post"
				display="flex"
				submitAlignment="right"
				submitDisabled={!comment}
				submitSize="small"
				submitUse={SVButtonUSES.TRANSPARENT}
				noPadding={!parentComment ? true : false}
			>
				<_ExpandingTextarea
					value={comment}
					onChange={(value) => setComment(value)}
					isReply={parentComment ? true : false}
					newCoordsComment={newCoords}
					handleSubmit={handleSubmit}
					isTeamOnly={isTeamOnly}
				/>
			</SVForm>
		</div>
	);
};

const _NewCommentBalloon = ({
	newCoords,
	commentAdd,
	assetID,
	handleNewCoordsComment,
	isTeamOnly,
	isInverted,
}: {
	newCoords: contentTypes['newCoordsComment'];
	commentAdd: Props['commentAdd'];
	assetID: Props['assetID'];
	handleNewCoordsComment: contentTypes['handleNewCoordsComment'];
	isTeamOnly: boolean;
	isInverted: boolean;
}) => {
	return (
		<div
			className="flex-center z-index-overlay absolute mt-[-42px] flex w-72 "
			style={{
				top: `${newCoords?.y}%`,
				left: `${newCoords?.x}%`,
			}}
			onClick={(e) => e.stopPropagation()}
		>
			<div
				className={clsx(
					'absolute h-9 w-9 rounded-full border-[1px] border-gray-900 border-opacity-25 bg-white',
					!isInverted ? 'left-0 top-2 rounded-bl-none' : '-left-9 top-2 rounded-br-none',
				)}
			>
				{' '}
			</div>
			<div
				className={clsx(
					'flex-center absolute top-0 ml-4 h-auto max-h-32 min-h-[14px] w-full rounded-lg border-[1px] border-gray-800 bg-gray-900 bg-opacity-70 align-middle backdrop-blur-lg',
					!isInverted ? 'left-9' : '-left-80 ml-[-18px]',
				)}
			>
				<_FormBallonInput
					commentAdd={commentAdd}
					assetID={assetID}
					newCoords={newCoords}
					handleNewCoordsComment={handleNewCoordsComment}
					isTeamOnly={isTeamOnly}
				/>
			</div>
		</div>
	);
};

const _balloonComment = ({
	comment,
	formatcomment,
}: {
	comment: NonNullable<Props['itemComments']>[0];
	formatcomment: (comment: string, userMentions: any) => string;
}) => (
	<div className="cursor-pointer text-xs">
		<div className="ml-0.5 mr-0.5 flex items-start space-x-3 text-start  text-sm text-gray-500">
			<SVAvatarsStack
				users={[comment.user]}
				visibleCount={1}
				size={SVAvatarsStack.SIZES.MEDIUM}
			/>
			<div className="text-primary mt-0.5 font-semibold">
				@{comment.user.username}
				<div
					className="ml-2 font-normal text-gray-400"
					style={{ display: 'inline', wordBreak: 'break-word' }}
					dangerouslySetInnerHTML={{
						__html: formatcomment(comment.content, comment.userMentions),
					}}
				/>
			</div>
		</div>
	</div>
);

const _balloonContent = ({
	comment,
	replies,
	commentAdd,
	assetID,
	handleDeleteComment,
	toggleCommentState,
	commentState,
	isTeamOnly,
	formatcomment,
}: {
	comment: NonNullable<Props['itemComments']>[0];
	replies: Props['itemComments'] | undefined | [];
	commentAdd: Props['commentAdd'];
	assetID: Props['assetID'];
	handleDeleteComment: Props['handleDeleteComment'];
	toggleCommentState: contentTypes['toggleCommentState'];
	commentState: contentTypes['commentState'];
	isTeamOnly: boolean;
	formatcomment: (comment: string, userMentions: any) => string;
}) => {
	const numberOfReplies = Array.isArray(replies)
		? replies.length >= 3
			? 3
			: replies.length + 1
		: 1;

	const useDistance =
		numberOfReplies === 1 ? 'right-16 -mr-4' : numberOfReplies === 2 ? 'right-16' : 'right-20';

	return (
		<div
			className={clsx(
				'overflow-y-none absolute h-auto max-h-[400px] w-[330px] rounded-xl bg-gray-900',
				commentState.state.isClicked ? 'p-6' : 'p-4',
				(comment.position?.y ?? 0) < 50 ? '-top-3.5' : '-bottom-3.5',
				!commentState.state.isClicked &&
					((comment.position?.y ?? 0) < 50
						? 'hover:rounded-tl-none'
						: ' hover:rounded-bl-none'),
				commentState.state.isClicked
					? (comment.position?.x ?? 0) < 50
						? 'ml-3'
						: useDistance
					: '',
			)}
			onClick={() =>
				toggleCommentState(comment._id, { isClicked: !commentState.state.isClicked })
			}
		>
			{commentState.state.isClicked && (
				<div className="flex justify-end pb-1">
					{_commentOptions(comment, true, handleDeleteComment)}
				</div>
			)}
			<div
				className={clsx(
					'max-h-[340px] overflow-y-auto ',
					commentState.state.isClicked && 'pb-16',
				)}
			>
				<_balloonComment comment={comment} formatcomment={formatcomment} />
				{replies &&
					replies.map((reply: NonNullable<Props['itemComments']>[0]) => (
						<div key={reply._id} className="pt-6">
							<_balloonComment comment={reply} formatcomment={formatcomment} />
						</div>
					))}
			</div>
			{commentState.state.isClicked && (
				<div className="flex-center absolute bottom-0 left-0 h-auto w-full  rounded-xl bg-gray-900 p-1 ">
					<_FormBallonInput
						commentAdd={commentAdd}
						assetID={assetID}
						parentComment={comment._id}
						isTeamOnly={isTeamOnly}
					/>
				</div>
			)}
		</div>
	);
};

const _BalloonSpotComments = ({
	comments,
	commentAdd,
	assetID,
	handleDeleteComment,
	toggleCommentState,
	getCorrectCommentState,
	isTeamOnly,
	formatcomment,
}: {
	comments: Props['itemComments'];
	commentAdd: Props['commentAdd'];
	assetID: Props['assetID'];
	handleDeleteComment: Props['handleDeleteComment'];
	toggleCommentState: contentTypes['toggleCommentState'];
	getCorrectCommentState: (commentId: string) => contentTypes['commentState'];
	isTeamOnly: boolean;
	formatcomment: (comment: string, userMentions: any) => string;
}) => {
	const commentsWithCoords = Array.isArray(comments)
		? comments.filter(
				(comment: any) => comment.position?.x && comment.position?.y && !comment.parent,
		  )
		: [];

	if (!commentsWithCoords.length) return null;

	const statesOfComments = commentsWithCoords.map((comment: any) =>
		getCorrectCommentState(comment._id),
	);

	return (
		<>
			{commentsWithCoords.map(
				(comment: NonNullable<Props['itemComments']>[0], index: number) => (
					<div
						key={comment._id}
						className={clsx(
							'group absolute z-10 -mt-7 cursor-pointer hover:z-20',
							statesOfComments[index].state.isClicked && 'z-40',
						)}
						style={{
							top: `${comment.position?.y ?? 0}%`,
							left: `${comment.position?.x ?? 0}%`,
						}}
						onClick={(e) => e.stopPropagation()}
						onMouseOver={() =>
							!statesOfComments[index].state.isClicked &&
							toggleCommentState(comment._id, { isHovered: true })
						}
						onMouseOut={() =>
							!statesOfComments[index].state.isClicked &&
							toggleCommentState(comment._id, { isHovered: false })
						}
					>
						<div
							className={clsx(
								'flex-center bg-background h-7 rounded-full rounded-bl-none',
								(statesOfComments[index].state.isHovered ||
									statesOfComments[index].state.isClicked) &&
									'border-primary border',
								!statesOfComments[index].state.isClicked &&
									statesOfComments[index].state.isHovered &&
									'group-hover:border-none',
							)}
						>
							<div
								className={clsx(
									'block p-1',
									!statesOfComments[index].state.isClicked &&
										'group-hover:hidden',
								)}
								onClick={() =>
									toggleCommentState(comment._id, {
										isClicked: !statesOfComments[index].state.isClicked,
									})
								}
							>
								<SVAvatarsStack
									users={[
										comment.user,
										...(Array.isArray(comments)
											? comments.filter(
													(c: any) => c.parent?._id === comment._id,
											  )
											: []
										).map((c: any) => c.user),
									]}
									visibleCount={3}
									showDropdown={false}
								/>
							</div>
							<div
								className={clsx(
									'relative transition-opacity duration-700 hover:opacity-100',
									statesOfComments[index].state.isClicked
										? 'block opacity-100 '
										: 'hidden opacity-0 group-hover:block',
								)}
							>
								<_balloonContent
									comment={comment}
									replies={
										Array.isArray(comments)
											? comments?.filter(
													(c: any) => c.parent?._id === comment._id,
											  )
											: ([] as any[])
									}
									commentAdd={commentAdd}
									assetID={assetID}
									commentState={statesOfComments[index]}
									handleDeleteComment={handleDeleteComment}
									toggleCommentState={toggleCommentState}
									isTeamOnly={isTeamOnly}
									formatcomment={formatcomment}
								/>
							</div>
						</div>
					</div>
				),
			)}
		</>
	);
};

const _Comment = ({
	comment,
	handleToggleLikeComment,
	handleReplyComment,
	replies,
	handleDeleteComment,
	toggleCommentState,
	commentState,
	formatComment,
}: {
	comment: NonNullable<Props['itemComments']>[0];
	handleToggleLikeComment: Props['handleToggleLikeComment'];
	handleReplyComment: (comment: contentTypes['replyComment']) => void;
	replies?: contentTypes['replies'];
	handleDeleteComment: Props['handleDeleteComment'];
	toggleCommentState: contentTypes['toggleCommentState'];
	commentState: contentTypes['commentState'];
	formatComment: (comment: string, userMentions: any) => string;
}) => {
	useEffect(() => {
		if (commentState.state.isClicked && !comment.position) {
			setTimeout(() => {
				toggleCommentState(comment._id, { isClicked: false });
			}, 3000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commentState.state.isClicked]);

	return (
		<div
			data-comment={comment._id}
			className={clsx(
				'pb-3 pt-3 pl-6 pr-6 text-sm transition-all duration-500',
				comment.position && 'cursor-pointer',
				commentState.state.isHovered &&
					comment.position &&
					replies &&
					!commentState.state.isClicked &&
					'bg-gray-900 bg-opacity-50',
				!replies && 'pr-0',
				commentState.state.isClicked && 'bg-gray-300 bg-opacity-[8%]',
			)}
			onMouseOver={() => {
				comment.position && toggleCommentState(comment._id, { isHovered: true });
			}}
			onMouseOut={() => {
				comment.position && toggleCommentState(comment._id, { isHovered: false });
			}}
			onClick={() =>
				comment.position &&
				replies &&
				toggleCommentState(comment._id, { isClicked: !commentState.state.isClicked })
			}
		>
			<div className="group flex items-start space-x-3">
				<div>
					<SVAvatarsStack
						users={[comment.user]}
						visibleCount={3}
						size={SVAvatarsStack.SIZES.MEDIUM}
					/>
				</div>
				<div className="w-full text-gray-300">
					<div className="mt-0.5 flex w-full justify-between text-sm">
						<div className="text-primary font-semibold">
							@{comment.user.username}
							<div
								className="ml-2 font-normal text-gray-400"
								style={{ display: 'inline', wordBreak: 'break-word' }}
								dangerouslySetInnerHTML={{
									__html: formatComment(comment.content, comment.userMentions),
								}}
							/>
						</div>
					</div>
					<div className="-sm:space-x-2 mt-1 inline-flex cursor-pointer space-x-3 whitespace-nowrap text-xs text-gray-500">
						<div>{formatRelativeDateShort(comment.createdAt).replace('about', '')}</div>
						{comment.likesCount > 0 ? (
							<div> {plural(comment.likesCount, 'Like')}</div>
						) : null}
						<div
							onClick={(e) => {
								e.stopPropagation();
								handleReplyComment({
									_id: comment.parent?._id ? comment.parent._id : comment._id,
									user: comment.user,
									content: comment.content,
								});
							}}
						>
							Reply
						</div>
						<div
							onClick={(e) => {
								e.stopPropagation();
								handleToggleLikeComment(comment._id);
							}}
						>
							{comment.isLiked ? 'Liked' : 'Like'}
						</div>
						<div className="-md:opacity-100 opacity-0 transition-all group-hover:opacity-100 ">
							{_commentOptions(comment, false, handleDeleteComment)}
						</div>
					</div>
				</div>
			</div>
			<div className="ml-2">{replies && replies(comment._id)}</div>
		</div>
	);
};

const _CommentInTheSide = ({
	comments,
	onToggleInfo,
	isInfoShown,
}: {
	comments: Props['itemComments'];
	onToggleInfo: Props['onToggleInfo'];
	isInfoShown: boolean;
}) => {
	const commentsWithOutCoords = comments?.filter(
		(comment: any) => !comment.position?.x && !comment.position?.y,
	);

	return (
		<div
			className="absolute bottom-3 left-3 flex cursor-pointer"
			onClick={(e) => {
				e.stopPropagation();
				if (!isInfoShown) {
					onToggleInfo();
				}
			}}
		>
			<SVAvatarsStack
				users={commentsWithOutCoords?.map((comment) => comment.user) || []}
				visibleCount={4}
				showDropdown={false}
			/>
		</div>
	);
};

const _SuggestedMentionUsers = ({
	suggestedUsers,
	handleUserClick,
	textAreaRef,
}: {
	suggestedUsers: NonNullable<Props['itemComments']>[0]['user'][];
	handleUserClick: (username: string) => void;
	textAreaRef: React.RefObject<HTMLTextAreaElement>;
}) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'ArrowDown') {
				setSelectedIndex((prevIndex) =>
					prevIndex < suggestedUsers.length - 1 ? prevIndex + 1 : prevIndex,
				);
			} else if (event.key === 'ArrowUp') {
				setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
			} else if (event.key === 'Enter' || event.key === 'Tab') {
				event.preventDefault();
				handleUserClick(suggestedUsers[selectedIndex].username);

				setTimeout(() => {
					textAreaRef.current?.focus();
				}, 100);
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIndex, suggestedUsers, handleUserClick]);

	return (
		<div className="z-index-overlay bg-background absolute bottom-14 left-10 max-h-60 w-52 overflow-y-auto rounded-xl border-[1px] border-gray-800 p-2">
			{suggestedUsers.map((user, index) => (
				<div
					key={user._id}
					className={clsx(
						'flex cursor-pointer items-center rounded-lg p-1.5',
						index === selectedIndex && 'bg-gray-900',
					)}
					onMouseOver={() => setSelectedIndex(index)}
					onClick={() => handleUserClick(user.username)}
				>
					<div className="flex w-full items-center space-x-2">
						<SVAvatarsStack
							users={[user]}
							visibleCount={1}
							size={SVAvatarsStack.SIZES.LARGE}
							showDropdown={false}
						/>
						<div className="truncate">
							<div className="text-primary text-sm font-semibold">{user.name}</div>
							<div className="text-[11px] text-gray-500">@{user.username}</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

const _ExpandingTextarea = ({
	value,
	onChange,
	isReply,
	newCoordsComment,
	handleSubmit,
	isTeamOnly,
}: {
	value: string;
	onChange: (e: string) => void;
	isReply: boolean;
	newCoordsComment?: contentTypes['newCoordsComment'];
	handleSubmit: () => void;
	isTeamOnly: boolean;
}) => {
	const [rows, setRows] = useState(1);
	const maxRows = newCoordsComment ? 6 : 2.5;
	const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
	const emojiButtonRef = useRef<HTMLButtonElement>(null);
	const emojiPickerRef = useRef<HTMLDivElement>(null);
	const [isDarkMode] = usePersistentSetting(PersistentSettingKeys.DARK_MODE);
	const [valueQuery, setValueQuery] = useState<string | null>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const { data: suggestedUsers, loading } = useQuery(SuggestedMentionedUsersQuery, {
		variables: {
			type: isTeamOnly ? MentionsType.TEAM : MentionsType.PUBLIC,
			query: valueQuery || '',
		},
	});

	const appendEmoji = (emoji: any) => {
		const newValue = `${value}${emoji.native}`;
		onChange(newValue);

		const event = {
			target: {
				value: newValue,
				rows: 1,
				scrollHeight: 0,
			},
		} as React.ChangeEvent<HTMLTextAreaElement>;

		if (textAreaRef.current) {
			textAreaRef.current.value = newValue;
			event.target = textAreaRef.current;
			handleChange(event);
		}
	};

	const handleUserClick = (username: string) => {
		const cursorPosition = value.lastIndexOf('@') + 1;
		const beforeAt = value.substring(0, cursorPosition);
		const afterAt = value.substring(cursorPosition).split(' ').slice(1).join(' ');
		const newValue = `${beforeAt}${username} ${afterAt}`;

		onChange(newValue);
		setValueQuery(null);

		if (textAreaRef.current) {
			textAreaRef.current.value = newValue;
			textAreaRef.current.focus();
		}
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			emojiPickerRef.current &&
			!emojiPickerRef.current.contains(event.target as Node) &&
			emojiButtonRef.current &&
			!emojiButtonRef.current.contains(event.target as Node)
		) {
			setShowEmojiPicker(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		if (textAreaRef.current && (newCoordsComment || isReply)) {
			textAreaRef.current.focus();
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [textAreaRef, newCoordsComment, isReply]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const textareaLineHeight = 24;
		const previousRows = event.target.rows;
		event.target.rows = 1;
		const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

		if (currentRows === previousRows) {
			event.target.rows = currentRows;
		}

		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}

		setRows(currentRows < maxRows ? currentRows : maxRows);

		const value = event.target.value;
		const cursorPosition = event.target.selectionStart;

		if (!loading) {
			const textBeforeCursor = value.substring(0, cursorPosition);
			const lastAtIndex = textBeforeCursor.lastIndexOf('@');

			if (lastAtIndex !== -1) {
				const query = textBeforeCursor.substring(lastAtIndex + 1);

				if (/[^a-zA-Z0-9_]/.test(query)) {
					setValueQuery(null);
				} else {
					setValueQuery(query);
				}
				return;
			}
			setValueQuery(null);
		}
	};

	useEffect(() => {
		if (value === '') {
			setRows(1);
			setValueQuery(null);
		}
	}, [value, newCoordsComment]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			if (suggestedUsers?.suggestedMentionedUsers?.length && valueQuery !== null) {
				event.preventDefault();
				return;
			}

			if (value !== '') {
				handleSubmit();
				textAreaRef.current && textAreaRef.current.blur();
			}
		}
	};

	return (
		<>
			{showEmojiPicker && (
				<div
					ref={emojiPickerRef}
					className={clsx(
						'z-index-overlay absolute bottom-20 left-4 w-full',
						newCoordsComment && 'bottom-8 -left-[0.5px] mb-8',
						isReply && 'bottom-10 left-0 mb-2',
					)}
				>
					<SVEmojiPicker
						onEmojiSelect={appendEmoji}
						previewPosition={isReply || (newCoordsComment && null)}
						searchPosition={isReply || (newCoordsComment && null)}
						isDarkMode={isDarkMode}
					/>
				</div>
			)}
			{(suggestedUsers?.suggestedMentionedUsers?.length ?? 0) > 0 && valueQuery !== null && (
				<_SuggestedMentionUsers
					suggestedUsers={
						suggestedUsers?.suggestedMentionedUsers.map((user) => ({
							...user,
							url: '',
						})) ?? []
					}
					textAreaRef={textAreaRef}
					handleUserClick={handleUserClick}
				/>
			)}
			<button
				ref={emojiButtonRef}
				className={clsx('flex-center mt-1 ml-5 w-14', newCoordsComment && 'ml-2')}
				onClick={() => setShowEmojiPicker(!showEmojiPicker)}
				type="button"
			>
				<IconHappyFaceSVG className="text-primary h-6 w-6" />
			</button>
			<textarea
				ref={textAreaRef}
				rows={rows}
				placeholder={isReply ? 'Reply to comment...' : 'Add comment...'}
				required
				value={value}
				className={clsx(
					'text-primary h-full w-[400%] resize-none overflow-hidden border-0 bg-transparent text-sm focus:outline-none focus:ring-0',
					rows === 1 && 'mt-1',
					newCoordsComment && 'w-[400%]',
				)}
				maxLength={500}
				onKeyDown={handleKeyDown}
				onChange={(e) => {
					handleChange(e);
					onChange(e.target.value);
				}}
			/>
		</>
	);
};

const _SendCommentContent = ({
	commentAdd,
	replyComment,
	handleReplyComment,
	assetID,
	handleNewCoordsComment,
	isTeamOnly,
}: {
	commentAdd: Props['commentAdd'];
	replyComment: contentTypes['replyComment'];
	handleReplyComment: (comment: contentTypes['replyComment']) => void;
	assetID: Props['assetID'];
	handleNewCoordsComment: contentTypes['handleNewCoordsComment'];
	isTeamOnly: boolean;
}) => {
	const [comment, setComment] = useState<string>('');

	const handleSubmit = async () => {
		commentAdd({
			assetID: assetID ?? '',
			content: comment,
			parentId: replyComment ? replyComment._id : null,
			isTeamOnly: isTeamOnly,
		});
		handleReplyComment(null);
		setComment('');
	};

	return (
		<div>
			<div
				className={clsx(
					'flex h-0 opacity-0 transition-opacity duration-1000',
					replyComment && 'h-14 overflow-hidden bg-gray-900 p-6 opacity-100',
				)}
			>
				{replyComment && (
					<div className="flex-center flex w-full items-center text-sm">
						<div className="w-72 items-center text-gray-500">
							<div className="text-primary pb-1 font-semibold">
								@{replyComment.user.username}
							</div>
							<div className="truncate">{replyComment.content}</div>
						</div>
						<div className="flex w-[20%] cursor-pointer justify-end">
							<IconCloseSVG
								className="text-primay h-3 w-3"
								onClick={() => handleReplyComment(null)}
							/>
						</div>
					</div>
				)}
			</div>
			<div
				className="flex-center relative flex h-16 w-full border-t-[1.5px] border-t-gray-800 pr-2 pb-1"
				onClick={() => handleNewCoordsComment(null)}
			>
				<SVForm
					onSubmit={() => handleSubmit()}
					noPadding
					display="flex"
					submitLabel="Post"
					submitAlignment="center"
					submitDisabled={!comment}
					submitUse={SVButtonUSES.TRANSPARENT}
					submitSize={SVButtonSIZES.EXTRA_SMALL}
				>
					<_ExpandingTextarea
						value={comment}
						onChange={(value) => setComment(value)}
						isReply={replyComment ? true : false}
						handleSubmit={handleSubmit}
						isTeamOnly={isTeamOnly}
					/>
				</SVForm>
			</div>
		</div>
	);
};

const _Label = ({ children }: { children: React.ReactNode }) => (
	<div className="pb-2 text-lg font-normal text-gray-500">{children}</div>
);

const _ItemInfo = ({
	children,
	Icon,
}: {
	children: React.ReactNode;
	Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}) => (
	<li className="flex">
		<Icon className="w-18 h-4 text-gray-600" aria-label="Hand tool" />
		{children}
	</li>
);

const _Tag = ({ tag }: { tag: NonNullable<Props['itemTags']>[0] }) => (
	<div className="flex-center flex rounded-full bg-gray-800 py-2 px-2.5 text-sm text-gray-500">
		<SVA key={tag.name} Component={SVLink} to={`/search/?q=${tag.name}`}>
			{tag.name}
		</SVA>
	</div>
);

const _Media = ({
	itemType,
	itemColor,
	itemThumbnail,
	itemOriginal,
	itemName,
	itemWidth,
	itemRatio,
	commentMode,
	isFitImage,
	itemComments,
	itemSourceURL,
	newCoordsComment,
	handleNewCoordsComment,
	assetID,
	commentAdd,
	canSeeComments,
	handleDeleteComment,
	toggleCommentState,
	getCorrectCommentState,
	isTeamOnly,
	formatComment,
	onToggleInfo,
	isInfoShown,
}: {
	itemType: Props['itemType'];
	itemColor: string;
	itemThumbnail: string;
	itemOriginal: string;
	itemName: string | undefined | null;
	itemWidth: number;
	itemRatio: number;
	commentMode: boolean;
	isFitImage: boolean;
	itemComments: Props['itemComments'];
	itemSourceURL: string | undefined | null;
	newCoordsComment: contentTypes['newCoordsComment'];
	handleNewCoordsComment: contentTypes['handleNewCoordsComment'];
	assetID: Props['assetID'];
	commentAdd: Props['commentAdd'];
	canSeeComments: boolean;
	handleDeleteComment: Props['handleDeleteComment'];
	toggleCommentState: contentTypes['toggleCommentState'];
	getCorrectCommentState: (commentId: string) => contentTypes['commentState'];
	isTeamOnly: boolean;
	formatComment: (comment: string, userMentions: any) => string;
	onToggleInfo: Props['onToggleInfo'];
	isInfoShown: boolean;
}) => {
	const [isCommentBalloonInverted, setIsCommentBalloonInverted] = useState<boolean>(false);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>, itemSourceURL: string) => {
		if (itemType !== AssetType.VIDEO) {
			if (commentMode) {
				if (newCoordsComment) {
					handleNewCoordsComment(null);
					toggleCommentState('', {});
					return;
				}
				const target = event.currentTarget as HTMLElement;
				if (target) {
					const rect = target.getBoundingClientRect();
					const clickX = ((event.clientX - rect.left) / rect.width) * 100;
					const clickY = ((event.clientY - rect.top) / rect.height) * 100;

					const clickXScreen =
						event.clientX + 354 > window.innerWidth - (isInfoShown ? 360 : 0);
					setIsCommentBalloonInverted(clickXScreen);
					toggleCommentState('', {});
					handleNewCoordsComment({ x: clickX, y: clickY });
				}
			} else {
				if (itemSourceURL) {
					window.open(itemSourceURL, '_blank');
				}
			}
		}
	};

	return (
		<div
			className={clsx('flex min-w-0', isFitImage ? 'm-auto h-full w-auto' : 'h-auto w-full')}
			style={{
				aspectRatio: `${itemWidth} / ${itemWidth * itemRatio}`,
				maxWidth: unit(itemWidth, 'px'),
				maxHeight: unit(itemWidth * itemRatio, 'px'),
			}}
		>
			<div
				className={clsx(
					'relative flex w-full min-w-0',
					isFitImage ? 'm-auto h-auto' : '',
					commentMode && canSeeComments && !newCoordsComment
						? 'cursor-comment'
						: 'cursor-pointer',
				)}
				style={{
					aspectRatio: `${itemWidth} / ${itemWidth * itemRatio}`,
					maxWidth: unit(itemWidth, 'px'),
					maxHeight: unit(itemWidth * itemRatio, 'px'),
				}}
				data-testid="image-container"
				onClick={(event) => handleClick(event, itemSourceURL || '')}
			>
				{itemType === AssetType.VIDEO ? (
					<_Video
						key={itemOriginal + 'original'}
						src={itemOriginal}
						poster={itemThumbnail}
					/>
				) : (
					<>
						<_Img
							key={itemThumbnail + 'thumbnail'}
							src={itemThumbnail}
							alt={itemName || undefined}
							itemColor={itemColor}
							data-testid="image-thumbnail"
						/>
						<_Img
							key={itemOriginal + 'original'}
							src={itemOriginal}
							data-testid="image-original"
							alt={itemName || undefined}
						/>
					</>
				)}
				{canSeeComments && commentMode && itemComments && (
					<_BalloonSpotComments
						comments={itemComments || []}
						commentAdd={commentAdd}
						assetID={assetID}
						handleDeleteComment={handleDeleteComment}
						toggleCommentState={toggleCommentState}
						isTeamOnly={isTeamOnly}
						getCorrectCommentState={getCorrectCommentState}
						formatcomment={formatComment}
					/>
				)}
				{canSeeComments && newCoordsComment && commentMode && (
					<_NewCommentBalloon
						newCoords={newCoordsComment}
						commentAdd={commentAdd}
						isTeamOnly={isTeamOnly}
						assetID={assetID}
						isInverted={isCommentBalloonInverted}
						handleNewCoordsComment={handleNewCoordsComment}
					/>
				)}
				{canSeeComments && commentMode && itemType !== AssetType.VIDEO && (
					<_CommentInTheSide
						comments={itemComments}
						onToggleInfo={onToggleInfo}
						isInfoShown={isInfoShown}
					/>
				)}
			</div>
		</div>
	);
};

const _Arrow = ({
	right,
	isInfoShown,
	...props
}: {
	right?: boolean;
	isInfoShown: boolean;
} & React.ComponentPropsWithoutRef<typeof SVLink>) => {
	const Icon = right ? IconArrowRightSVG : IconArrowLeftSVG;

	return (
		<SVLink
			className={clsx(
				// TODO: Fix this arrow top position, it should be better placed to not clash with the top nav
				'flex-center fixed top-[92px] h-screen w-[8%]',
				right
					? 'touch:right-[env(safe-area-inset-right)] right-0'
					: 'touch:left-[env(safe-area-inset-left)] left-0',
				right && isInfoShown && 'translate-x-[-360px]',
			)}
			{...props}
		>
			<Icon className="touch:opacity-100 -md:h-[20px] -md:w-[12px] opacity-0" />
		</SVLink>
	);
};

const _InfoColorsItem = ({ color }: { color: string }) => (
	<SVLink
		className="duration-over-long shadow-inner-1 group flex h-10 w-10 min-w-0 cursor-pointer items-center justify-center overflow-hidden rounded-[20px] transition-all delay-[100ms] hover:flex-auto hover:delay-[0ms]"
		title={`Search by ${color}`}
		to={{
			pathname: '/search/',
			query: { q: color },
		}}
		style={{
			backgroundColor: color,
		}}
	>
		<IconSearchSVG
			className={twMerge(
				'duration-over-long h-3 w-3 opacity-0 transition-opacity group-hover:opacity-100',
				(colorHEXToHSV(color)?.v || 0) >= 50 ? 'text-black' : 'text-white',
			)}
		/>
	</SVLink>
);

const _ToasterCommentMode = ({
	commentMode,
	isInfoShown,
}: {
	commentMode: boolean;
	isInfoShown: boolean;
}) => {
	const [transition, setTransition] = useState<boolean>(false);
	const transitionTimeRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const showTimeRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const [showToaster, setShowToaster] = useState<boolean | null>(null);

	const toggleNotification = () => {
		transitionTimeRef.current = setTimeout(() => {
			setTransition(true);
		}, 100);

		transitionTimeRef.current = setTimeout(() => {
			setTransition(false);
		}, 1500);
		showTimeRef.current = setTimeout(() => {
			setShowToaster(false);
		}, 2000);
	};

	useEffect(() => {
		if (showToaster === null) {
			setShowToaster(false);
			return;
		}
		setShowToaster(true);
		setTransition(false);
		toggleNotification();

		return () => {
			transitionTimeRef.current && clearTimeout(transitionTimeRef.current);
			showTimeRef.current && clearTimeout(showTimeRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commentMode]);

	return (
		<div
			className={clsx(
				'fixed bottom-4 flex transform justify-center transition-all duration-[400ms]',
				isInfoShown ? 'w-[calc(100%-360px)]' : 'w-full',
			)}
		>
			<div
				className={clsx(
					'z-index-grid-detail-comment text-primary flex rounded-lg bg-gray-900 p-4 text-lg opacity-100 transition-all duration-700',
					{
						'translate-y-[0%]': transition,
						'translate-y-[120%]': !transition,
					},
					!showToaster && 'hidden',
				)}
			>
				{commentMode ? "You've activated comments mode" : 'Comments are off'}
			</div>
		</div>
	);
};

const _RelatedGrid = React.memo(
	({
		itemID,
		itemShortID,
		onVisible,
		onHidden,
	}: {
		itemID: ItemFragmentFragment['_id'];
		itemShortID: ItemFragmentFragment['shortID'];
		onVisible: () => void;
		onHidden: () => void;
	}) => {
		const client = useApolloClient();
		const [isInView, setIsInView] = useState(false);
		const previousShortID = usePrevious(itemShortID);

		const { items, loading, paginate } = usePaginatedQuery(RelatedItemsQuery, {
			variables: {
				shortID: itemShortID,
			},
			skip: !isInView,
		});

		const handleObserverChange = useEventCallback((inView: boolean) => {
			if (inView && !isInView) {
				onVisible();
			} else if (!inView && isInView) {
				onHidden();
			}

			setIsInView(inView);
		});

		const handleItemClick = useEventCallback((relatedItemID: ItemFragmentFragment['_id']) => {
			client.mutate({
				mutation: TrackRelatedItemClickMutation,
				variables: {
					input: {
						itemID,
						relatedItemID,
					},
				},
			});
		});

		// Only set as not in view if item has changed
		useEffect(() => {
			if (previousShortID !== itemShortID && isInView) {
				setIsInView(false);
			}
		}, [itemShortID, previousShortID, isInView]);

		// Show skeleton if not in view yet and haven't loaded
		const isLoading = loading || (!items && !isInView);

		return (
			<Observer onChange={handleObserverChange}>
				<SVGridContainer
					sourceType={SVGrid.SOURCE_TYPES.RELATED}
					items={items}
					isLoading={isLoading}
					loadingSkeletonCount={20}
					onPaginate={paginate}
					onItemClick={handleItemClick}
				/>
			</Observer>
		);
	},
);

// Resets scroll of the wrapper div to top whenever the route changes
// This is necessary when navigating between related items
const _ResetScrollOnRouteChange = ({
	wrapperRef,
}: {
	wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
	const router = useRouter();
	const pathname = router.asPath;
	const lastPathname = usePrevious(pathname);

	useEffect(() => {
		if (lastPathname !== pathname) {
			if (wrapperRef.current) {
				wrapperRef.current.scrollTop = 0;
			}
		}
	}, [lastPathname, pathname, wrapperRef]);

	return null;
};

const SVGridDetailOverlayContent = (props: Props & { viewportName: ViewportNameType }) => {
	const router = useRouter();
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [replyComment, setReplyComment] = useState<contentTypes['replyComment'] | null>(null);

	const [commentState, setCommentState] = useState<null | Array<contentTypes['commentState']>>(
		null,
	);
	const [newCoordsComment, setNewCoordsComment] = useState<
		null | contentTypes['newCoordsComment']
	>(null);
	const [isFitImage, setIsFitImage] = usePersistentSetting(PersistentSettingKeys.FIT_TO_SCREEN);
	const [commentMode, setCommentMode] = useState<boolean>(false);

	const {
		itemSourceDisplayURL,
		itemSourceURL,
		itemName,
		itemDate,
		itemColor,
		itemThumbnail,
		itemOriginal,
		itemWidth,
		itemRatio,
		assetID,
		hasRelated,
		itemShortID,
		itemID,
		itemSavedCount,
		itemViewsCount,
		itemUsers,
		itemColors,
		itemType,
		itemTags,
		itemComments,
		commentAdd,
		handleToggleLikeComment,
		boardList,
		renderDropdownContent,
		canSeeComments,
		viewportName,
		isFullscreen,
		handleDeleteComment,
		closeURL,
		isInfoShown,
		onToggleInfo,
		isOwner,
		prevURL,
		userTeam,
		prevURLAs,
		nextURL,
		nextURLAs,
		onEdit,
		children,
		item,
	} = props;

	const [isTeamOnly, setIsTeamOnly] = useState<boolean>(userTeam ? true : false);
	const [isRelatedGridVisible, setIsRelatedGridVisible] = useState<boolean>(false);

	const showInfo = !isFullscreen && isInfoShown;

	const registerCommentStates = () => {
		const newCommentStates =
			itemComments &&
			itemComments.map((comment) => ({
				commentID: comment._id,
				state: { isHovered: false, isClicked: router.query?.commentID === comment._id },
			}));

		setCommentState(newCommentStates || []);
	};

	const handleToggleCommentMode = (force?: boolean) => {
		if (!commentMode && !isInfoShown) {
			onToggleInfo();
		}
		setCommentMode(force ?? !commentMode);
	};

	useEffect(() => {
		if (router.query?.commentID) {
			if (!isInfoShown) {
				onToggleInfo();
			}
			const mentionComment = itemComments?.find(
				(comment) => comment._id === router.query?.commentID,
			);

			handleToggleCommentMode(true);

			if (mentionComment?.isTeamOnly !== isTeamOnly) {
				setIsTeamOnly(mentionComment?.isTeamOnly || false);
			}

			scrollToMention();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.query?.commentID, isInfoShown, itemComments]);

	useEffect(() => {
		registerCommentStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemComments]);

	const scrollToMention = () => {
		const handleFirstTimeout = () => {
			const commentList = document.getElementById('infoSideBar') as HTMLElement;
			const comment = document.querySelector(
				`[data-comment="${router.query?.commentID}"]`,
			) as HTMLElement;

			comment &&
				commentList &&
				scrollToElement(commentList, comment.offsetTop - 200 || 0, true, true);

			const { commentID: _, ...restOfQuery } = router.query;
			router.query = { ...restOfQuery };
		};

		router.query?.commentID && setTimeout(handleFirstTimeout, 400);
	};

	const formatComment = (
		content: string,
		mentions: ItemDetailsQuery['itemByShortID']['asset']['comments'][0]['userMentions'],
	) => {
		if (!content || !mentions || mentions.length === 0) {
			return content;
		}

		let formattedContent = '';
		let lastIndex = 0;

		mentions &&
			mentions.forEach((mention) => {
				const start = mention.indexStart;
				const end = mention.indexEnd;
				const username = mention.user.username;

				formattedContent += content.slice(lastIndex, start);

				formattedContent += `<a href="/${username}/" style="color: #5869C7" class="cursor-pointer">@${username}</a>`;

				lastIndex = end;
			});

		formattedContent += content.slice(lastIndex);

		return formattedContent;
	};

	const handleToggleIsTeamOnly = (state: boolean) => {
		setIsTeamOnly(state);
		setReplyComment(null);
		setNewCoordsComment(null);
	};

	const handleToggleCommentState = (
		commentId: string,
		newState: { isHovered?: boolean; isClicked?: boolean },
	) => {
		if (newState.isClicked) {
			handleToggleCommentMode(true);
		}
		const newCommentState:
			| Array<{ commentID: string; state: { isHovered: boolean; isClicked: boolean } }>
			| undefined = commentState?.map((comment) => {
			if (comment.commentID === commentId) {
				return { commentID: commentId, state: { ...comment.state, ...newState } };
			}

			return {
				commentID: comment.commentID,
				state: {
					isHovered: false,
					isClicked: newState?.isHovered !== undefined ? comment.state.isClicked : false,
				},
			};
		});

		!newCommentState && registerCommentStates();

		newCommentState && setCommentState(newCommentState);
	};

	const handleRelatedGridVisible = useEventCallback(() => {
		setIsRelatedGridVisible(true);
	});

	const handleRelatedGridHidden = useEventCallback(() => {
		setIsRelatedGridVisible(false);
	});

	const comments = itemComments?.filter((comment) => comment.isTeamOnly === isTeamOnly);

	const teamOnlyCommentsLength =
		itemComments?.filter((comment) => comment.isTeamOnly === true).length ?? 0;

	const publicCommentsLength =
		itemComments?.filter((comment) => comment.isTeamOnly === false).length ?? 0;

	const isSmallAny = viewportName === 'small' || viewportName === 'small-landscape';

	const avatarsVisibleCount = isSmallAny ? 3 : 6;

	const hasSource = itemSourceDisplayURL && itemSourceURL;

	const getCorrectCommentState = (commentId: string) => {
		const newCommentState = commentState?.find((state) => state.commentID === commentId);
		return newCommentState
			? newCommentState
			: { commentID: '', state: { isHovered: false, isClicked: false } };
	};

	// TODO: Take this out of the render and pass props to it
	const replies = (commentId: string) => {
		const UseReplies = () => {
			const replies = comments?.filter((comment) => comment.parent?._id === commentId);
			const [showReplies, setShowReplies] = useState<boolean>(
				replies?.some((reply) => reply._id === router?.query?.commentID) ?? false,
			);
			return { showReplies, setShowReplies, replies };
		};
		const { showReplies, setShowReplies, replies } = UseReplies();

		return (
			<div>
				{(replies?.length ?? 0) > 0 && (
					<div
						className="flex w-40 cursor-pointer items-center space-x-3 pl-6 pt-4 text-xs  text-gray-500"
						onClick={(e) => {
							e.stopPropagation();
							setShowReplies(!showReplies);
						}}
					>
						<div className="h-[1px] w-6 bg-gray-500" />
						<div>
							{showReplies ? 'Hide' : 'See'} replies{' '}
							{!showReplies && `(${replies?.length})`}
						</div>
					</div>
				)}
				<div
					className={clsx(
						'transition-all duration-300 ease-in-out',
						showReplies ? 'max-h-full opacity-100' : 'max-h-0 opacity-0',
						'overflow-hidden',
					)}
				>
					{replies &&
						replies.map((reply) => (
							<_Comment
								key={reply._id}
								comment={reply}
								handleToggleLikeComment={handleToggleLikeComment}
								handleReplyComment={setReplyComment}
								handleDeleteComment={handleDeleteComment}
								commentState={getCorrectCommentState(reply._id)}
								toggleCommentState={handleToggleCommentState}
								formatComment={formatComment}
							/>
						))}
				</div>
			</div>
		);
	};

	const actionItems = [item];

	return (
		<div
			// key={item._id}
			className="z-index-overlay bg-background max-w-screen fixed inset-0 block overflow-y-auto overflow-x-hidden"
			ref={wrapperRef}
		>
			<div className="relative flex min-h-screen w-full">
				<div className="flex min-h-screen flex-1 flex-col">
					{!isFullscreen && (
						<div
							className={clsx(
								'bg-background duration-slide -sm:px-[var(--page-margin)] relative py-6 px-[calc(var(--page-margin)/2)] transition-all ease-in-out',
								showInfo && 'pr-6',
							)}
						>
							<ul className="flex items-center space-x-8">
								<li>
									{itemUsers ? (
										<SVAvatarsStack
											users={itemUsers}
											visibleCount={avatarsVisibleCount}
											firstIsAuthor={true}
										/>
									) : (
										<div className="flex-center flex min-h-[44px]">
											<div className="bg-muted h-5 w-5 rounded-full" />
										</div>
									)}
								</li>
								<SVFlexSpacer />
								<li>
									<SVActions use={SVActions.USES.SUBNAV} className="flex-wrap">
										<SVSaveItemsActionContainer
											use={SVActions.USES.SUBNAV}
											items={actionItems}
										/>
										{!isSmallAny && (
											<SVActions.Item
												onClick={() => setIsFitImage(!isFitImage)}
												title="Fit image to screen"
												keys={'f'}
											>
												{isFitImage ? 'Scale' : 'Fit'}
											</SVActions.Item>
										)}
										{canSeeComments &&
											!isSmallAny &&
											viewportName !== 'medium-portrait' && (
												<SVActions.Item
													onClick={() => handleToggleCommentMode()}
													keys={'c'}
												>
													<div className="flex items-center">
														Comments{' '}
														{(itemComments?.length ?? 0) > 0 && (
															<div className=" flex-center ml-2 flex h-6 w-6 rounded-full bg-gray-800 text-xs">
																{(itemComments?.length ?? 0) > 99
																	? '99+'
																	: itemComments?.length}
															</div>
														)}
													</div>
												</SVActions.Item>
											)}
										<SVActions.Item keys={'i'} onClick={onToggleInfo}>
											Info
										</SVActions.Item>
										{!isSmallAny && renderDropdownContent && (
											<SVDropdown
												renderTrigger={({ isOpen: _, ...props }) => (
													<SVIconButton
														className="text-gray-500 text-opacity-100"
														{...props}
														src={IconMoreSVG}
													/>
												)}
												renderContent={renderDropdownContent}
											/>
										)}
									</SVActions>
								</li>
								{!showInfo && (
									<>
										{closeURL && (
											<li>
												<SVIconButton
													Component={SVLink}
													to={closeURL}
													src={IconCloseSVG}
													label="Close"
													className="text-gray-500 text-opacity-100"
													scroll={false}
													keys="escape"
												/>
											</li>
										)}
									</>
								)}
								{showInfo && (
									<li>
										<SVIconButton
											Component="button"
											onClick={onToggleInfo}
											src={IconBack2SVG}
											className="text-gray-500"
											label="Hide info"
										/>
									</li>
								)}
							</ul>
						</div>
					)}
					<div
						className={clsx(
							'relative flex flex-grow',
							!isFitImage && 'items-center justify-center',
						)}
					>
						<_Media
							itemType={itemType}
							itemColor={itemColor}
							itemThumbnail={itemThumbnail}
							itemOriginal={itemOriginal}
							itemName={itemName}
							itemWidth={itemWidth}
							itemRatio={itemRatio}
							isFitImage={isFitImage}
							itemComments={comments}
							commentMode={commentMode}
							itemSourceURL={itemSourceURL}
							newCoordsComment={newCoordsComment}
							handleNewCoordsComment={setNewCoordsComment}
							assetID={assetID}
							commentAdd={commentAdd}
							onToggleInfo={onToggleInfo}
							canSeeComments={canSeeComments}
							handleDeleteComment={handleDeleteComment}
							toggleCommentState={handleToggleCommentState}
							getCorrectCommentState={getCorrectCommentState}
							isTeamOnly={isTeamOnly}
							isInfoShown={showInfo}
							formatComment={formatComment}
						/>
					</div>
					{!isFullscreen ? <div className="h-nav-height" /> : null}
					{canSeeComments && (
						<_ToasterCommentMode commentMode={commentMode} isInfoShown={showInfo} />
					)}

					{prevURL && (
						<_Arrow
							isInfoShown={showInfo}
							to={prevURL}
							as={prevURLAs || undefined}
							title="Previous"
							keys="arrowleft"
							scroll={false}
						/>
					)}
					{nextURL && (
						<_Arrow
							right={true}
							isInfoShown={showInfo}
							to={nextURL}
							as={nextURLAs || undefined}
							title="Next"
							keys="arrowright"
							scroll={false}
						/>
					)}
				</div>
				<SVTransition
					show={showInfo}
					className="duration-slide relative flex flex-col transition-all ease-in-out"
					enterFrom="w-0"
					enterTo="w-[360px]"
				>
					<div
						className={clsx(
							'transition-opacity duration-300 ease-out',
							isRelatedGridVisible
								? 'pointer-events-none opacity-0'
								: 'pointer-events-auto opacity-100',
							showInfo ? 'w-[360px]' : 'w-0',
						)}
					>
						{/* TODO: (related-saves) See if we can do a crossbrowser 'sticky' position */}
						<div className="z-index-grid-detail-info -sm:left-0 -sm:w-screen bg-background fixed box-border flex h-full w-[360px] flex-col overflow-hidden border-l-[1.5px] border-gray-800">
							<ul className="mt-[14px] flex flex-shrink-0 items-center space-x-5 p-6 pb-4">
								<div className="-smp:block hidden">
									<SVIconButton
										Component="button"
										onClick={onToggleInfo}
										src={IconBack2SVG}
										label="Hide info"
										className="text-gray-400"
										iconClassName="min-h-0 min-w-0 h-4 w-4"
									/>
								</div>
								<SVFlexSpacer />
								{isOwner && onEdit && (
									<li>
										<SVIconButton
											src={IconEditSVG}
											onClick={onEdit}
											className="text-gray-400 text-opacity-100"
											label="Edit"
											iconClassName="min-h-0 min-w-0 h-4 w-4"
										/>
									</li>
								)}
								{isSmallAny && renderDropdownContent && (
									<li>
										<SVDropdown
											renderTrigger={({ isOpen: _, ...props }) => (
												<SVIconButton
													{...props}
													src={IconMoreSVG}
													className="text-gray-500 text-opacity-100"
												/>
											)}
											triggerType={SVDropdown.TRIGGER_TYPES.HOVER}
											positionStrategy={SVDropdown.POSITION_STRATEGIES.FIXED}
											renderContent={renderDropdownContent}
										/>
									</li>
								)}
								{closeURL && (
									<li>
										<SVIconButton
											Component={SVLink}
											to={closeURL}
											src={IconCloseSVG}
											label="Close"
											className="text-gray-400 text-opacity-100"
											scroll={false}
											keys="escape"
											iconClassName="min-h-0 min-w-0 h-4 w-4"
										/>
									</li>
								)}
							</ul>
							<div
								className="flex flex-1 flex-col justify-between overflow-y-auto overflow-x-visible"
								id="infoSideBar"
							>
								<div className="space-y-10">
									<div className="space-y-10 px-6">
										<div className="space-y-2 whitespace-normal break-all">
											{hasSource && (
												<div className="text-primary border-primay inline-block border-b-[1px] text-sm">
													<SVLink
														to={itemSourceURL}
														target="_blank"
														rel="noreferrer"
													>
														{itemSourceDisplayURL}
													</SVLink>
												</div>
											)}

											<div className="line-clamp-3 text-overflow max-h-[65px] overflow-hidden text-lg">
												{itemName}
											</div>
											{itemDate && (
												<div className="flex justify-between text-sm text-gray-600">
													{formatRelativeDateShort(itemDate, true)}
													{(itemSavedCount != null ||
														itemViewsCount != null) && (
														<div className="flex items-center">
															<ul className="flex space-x-4">
																{itemSavedCount != null && (
																	<_ItemInfo Icon={IcontAdd}>
																		{itemSavedCount}
																	</_ItemInfo>
																)}
																{itemViewsCount != null && (
																	<_ItemInfo Icon={IconBarsStats}>
																		{itemViewsCount}
																	</_ItemInfo>
																)}
															</ul>
														</div>
													)}
												</div>
											)}
											{itemColors && itemColors.length && (
												<div className="flex space-x-1 pt-4">
													{itemColors.map((color) => (
														<_InfoColorsItem
															key={color.color}
															color={color.color}
														/>
													))}
												</div>
											)}
										</div>

										{itemTags && itemTags.length ? (
											<div>
												<_Label>AI Tags</_Label>
												<div className="flex flex-wrap gap-1.5">
													{itemTags.map((tag) => (
														<_Tag key={tag.name} tag={tag} />
													))}
												</div>
											</div>
										) : null}
										{boardList && <div>{boardList}</div>}
										<SVFlexSpacer />
									</div>
									{canSeeComments ? (
										<div>
											<div className="flex items-center justify-between px-6">
												{userTeam ? (
													<SVDropdown
														triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
														renderTrigger={({
															isOpen: _,
															...props
														}) => (
															<button
																className="flex-center flex space-x-2.5"
																{...props}
															>
																<div className="-mb-2 flex space-x-2">
																	<_Label>
																		{isTeamOnly
																			? plural(
																					teamOnlyCommentsLength,
																					'Team Comment',
																					null,
																					teamOnlyCommentsLength ===
																						0,
																			  )
																			: plural(
																					publicCommentsLength,
																					'Public Comment',
																					null,
																					publicCommentsLength ===
																						0,
																			  )}
																	</_Label>
																</div>
																<SVIconButton
																	iconClassName="w-[12px] h-[12px] min-w-[12px] min-h-[12px] text-gray-500"
																	src={IconCaretSVG}
																/>
															</button>
														)}
														renderContent={() => (
															<div className="bg-background flex flex-col px-2 py-1">
																<SVDropdownContent.Links.Item
																	onClick={() => {
																		handleToggleIsTeamOnly(
																			false,
																		);
																	}}
																>
																	<div className="flex w-full cursor-pointer justify-between space-x-10 rounded-lg p-1.5 text-gray-500 hover:bg-gray-900 hover:text-gray-200">
																		<div> Public </div>
																		<div className="text-right">
																			{publicCommentsLength >
																			0
																				? publicCommentsLength
																				: null}
																		</div>
																	</div>
																</SVDropdownContent.Links.Item>
																<SVDropdownContent.Links.Item
																	onClick={() => {
																		handleToggleIsTeamOnly(
																			true,
																		);
																	}}
																>
																	<div className="-mt-2 flex w-full cursor-pointer justify-between space-x-10 rounded-lg p-1.5 text-gray-500 hover:bg-gray-900 hover:text-gray-200">
																		<div> Team </div>
																		<div className="text-right">
																			{teamOnlyCommentsLength >
																			0
																				? teamOnlyCommentsLength
																				: null}
																		</div>
																	</div>
																</SVDropdownContent.Links.Item>
															</div>
														)}
													/>
												) : (
													<div className="-mb-2">
														<_Label>Comments</_Label>
													</div>
												)}
												<div className="flex items-center">
													<SVToggle
														isPressed={commentMode}
														onClick={() => handleToggleCommentMode()}
													/>
												</div>
											</div>

											{comments && comments.length === 0 ? (
												<div className="pl-6 pr-6 pt-3 text-sm font-medium">
													<div className="text-gray-700">
														No comments yet! Add one to start the
														conversation.
													</div>
												</div>
											) : null}

											{comments && comments.length
												? comments.map(
														(comment) =>
															!comment.parent && (
																<div key={comment._id}>
																	<_Comment
																		comment={comment}
																		handleToggleLikeComment={
																			handleToggleLikeComment
																		}
																		handleReplyComment={
																			setReplyComment
																		}
																		replies={replies}
																		handleDeleteComment={
																			handleDeleteComment
																		}
																		commentState={getCorrectCommentState(
																			comment._id,
																		)}
																		toggleCommentState={
																			handleToggleCommentState
																		}
																		formatComment={
																			formatComment
																		}
																	/>
																</div>
															),
												  )
												: null}
										</div>
									) : null}
								</div>
							</div>
							{canSeeComments ? (
								<div>
									<_SendCommentContent
										commentAdd={commentAdd}
										replyComment={replyComment}
										handleReplyComment={setReplyComment}
										assetID={assetID}
										handleNewCoordsComment={setNewCoordsComment}
										isTeamOnly={isTeamOnly}
									/>
								</div>
							) : null}
						</div>
					</div>
				</SVTransition>
			</div>
			{children}
			{!isFullscreen && hasRelated ? (
				<div className="relative mt-4">
					<_RelatedGrid
						itemID={itemID}
						itemShortID={itemShortID}
						onVisible={handleRelatedGridVisible}
						onHidden={handleRelatedGridHidden}
					/>
				</div>
			) : null}
			<_ResetScrollOnRouteChange wrapperRef={wrapperRef} />
		</div>
	);
};

type contentTypes = {
	replies: (commentId: string) => React.ReactNode;
	commentState: { commentID: string; state: { isHovered: boolean; isClicked: boolean } };
	replyComment: null | {
		_id: string;
		content: string;
		user: { name: string; avatarURL: string; username: string; url: string };
	};
	toggleCommentState: (
		commentId: string,
		state: { isHovered?: boolean; isClicked?: boolean },
	) => void;
	handleNewCoordsComment: (coords: { x: number; y: number } | null) => void;
	newCoordsComment: { x: number; y: number } | null;
};

type Props = React.PropsWithChildren<{
	itemSourceDisplayURL: string | null | undefined;
	itemSourceURL: string | null | undefined;
	itemName: string | null | undefined;
	itemDate: Date | null | undefined;
	itemColor: string;
	itemThumbnail: string;
	itemOriginal: string;
	itemWidth: number;
	itemRatio: number;
	item: ItemFragmentFragment;
	assetID: string | null | undefined;
	hasRelated: boolean | null | undefined;
	itemShortID: string;
	itemID: ItemFragmentFragment['_id'];
	itemColors: ItemFragmentFragment['asset']['colors'];
	itemType: ItemFragmentFragment['asset']['type'];
	itemUsers: ItemDetailsQuery['itemByShortID']['asset']['users'] | null | undefined;
	itemTags: ItemDetailsQuery['itemByShortID']['asset']['tags'] | null | undefined;
	itemSavedCount: number;
	itemViewsCount: number;
	isLoadingDetails: boolean;
	isFullscreen: boolean;
	isOwner: boolean;
	prevURL: React.ComponentProps<typeof SVLink>['to'] | null;
	prevURLAs: string | null;
	nextURL: React.ComponentProps<typeof SVLink>['to'] | null;
	nextURLAs: string | null;
	onEdit: () => void;
	closeURL: React.ComponentProps<typeof SVLink>['to'];
	renderDropdownContent: () => JSX.Element;
	boardList: JSX.Element | null;
	itemComments: ItemDetailsQuery['itemByShortID']['asset']['comments'] | [] | undefined;
	handleToggleLikeComment: (commentID: string) => void;
	commentAdd: (params: {
		assetID: string;
		content: string;
		parentId?: string | null;
		position?: { x: number; y: number };
		isTeamOnly: boolean;
	}) => void;
	canSeeComments: boolean;
	handleDeleteComment: (commentID: string) => void;
	userTeam: TeamFragmentFragment | null | undefined;
	isInfoShown: boolean;
	onToggleInfo: () => void;
}>;

const SVGridDetailOverlay = (props: Props) => {
	const viewportName = useViewportName();

	return <SVGridDetailOverlayContent viewportName={viewportName || 'xx-large'} {...props} />;
};

SVGridDetailOverlay.itemToProps = (
	item: ItemFragmentFragment,
	itemDetails: ItemDetailsQuery['itemByShortID'] | null,
) => ({
	itemSourceDisplayURL: item.sourceDisplayURL,
	itemSourceURL: item.sourceURL,
	itemName: item.name,
	itemDate: item.createdAt,
	itemColor: item.asset.colors[0].color,
	itemThumbnail: item.asset.image.thumbnail,
	itemOriginal: item.asset.image.original,
	itemWidth: item.asset.image.width,
	itemRatio: item.asset.image.ratio,
	itemType: item.asset.type,
	itemColors: item.asset.colors,
	itemUsers: itemDetails?.asset.users,
	itemSavedCount: item.asset.itemsCount,
	itemViewsCount: item.asset.viewsCount,
	itemComments: itemDetails?.asset.comments,
	itemTags: itemDetails?.asset.tags,
	item: item,
	assetID: itemDetails?.asset._id,
	hasRelated: itemDetails?.asset.hasRelated,
	itemShortID: item.shortID,
	itemID: item._id,
});

SVGridDetailOverlay.preloadItem = async (item: ItemFragmentFragment) => {
	if (item.asset.type === AssetType.IMAGE) {
		await preloadImage(item.asset.image.original);
	}
};

export default SVGridDetailOverlay;
