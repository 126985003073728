import childrenOnlyText from '@pkgs/shared-client/helpers/childrenOnlyText';
import matchLocation from '@pkgs/shared-client/helpers/matchLocation';
import IconLoadingSVG from '@pkgs/shared-client/img/icon-loading-inlined.svg';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVKeyboardKey, { formatTitleWithKeys, type KeyboardKeys } from './SVKeyboardKey';
import SVLink, { type Props as SVLinkProps } from './SVLink';
import SVToggle from './SVToggle';

const USES = {
	DEFAULT: 'default',
	SUBNAV: 'subnav',
	PAGENAV: 'pagenav',
	FEEDNAV: 'feednav',
	RETRACTABLE_BAR: 'retractable-bar',
} as const;

const defaultProps: {
	use: ValueOf<typeof USES>;
} = {
	use: USES.DEFAULT,
};

/*
TODO:
	?
	.print-cell
		-small-any display none

*/

const MAX_WIDTH_CLASSES = [
	'max-w-[150px] -smp:max-w-[100px]',
	'max-w-[300px] -smp:max-w-[200px]',
	'max-w-[450px] -smp:max-w-[300px]',
	'max-w-[600px] -smp:max-w-[400px]',
];

type ItemProps = React.PropsWithChildren<
	Partial<typeof defaultProps> & {
		keys?: KeyboardKeys;
		isLoading?: boolean;
		isCurrent?: boolean;
		isPressed?: boolean;
		isDisabled?: boolean;
		to?: SVLinkProps['to'] | undefined;
		title?: React.HTMLProps<HTMLButtonElement>['title'];
		target?: SVLinkProps['target'];
	} & (
			| ({
					Component: typeof SVLink;
			  } & React.ComponentPropsWithoutRef<typeof SVLink>)
			| {
					Component?: 'button';
					onClick: (event: React.UIEvent) => void;
			  }
		)
>;

const SVActionsItem = React.forwardRef<HTMLElement, ItemProps>(
	(
		{
			Component = 'button',
			keys,
			use,
			isLoading,
			isCurrent,
			isPressed,
			isDisabled,
			onClick,
			target,
			title,
			children,
			...props
		},
		forwardedRef,
	) => {
		const isNav = use === USES.SUBNAV || use === USES.PAGENAV || use === USES.FEEDNAV;

		if (isCurrent && use === USES.FEEDNAV) {
			Component = 'button';
		}

		return (
			<li
				// @ts-expect-error fix this later
				ref={forwardedRef}
				className={clsx(
					'flex-center relative flex-grow',
					(isLoading || isCurrent || isDisabled) && use !== USES.FEEDNAV && 'disabled',
				)}
			>
				<div
					className={clsx(
						use === USES.DEFAULT && '-smp:py-1 px-2',
						use === USES.RETRACTABLE_BAR && 'px-3',
					)}
				>
					{isLoading && (
						<span
							className="flex-center pointer-events-none absolute inset-0"
							key="wrapper"
							role="progressbar"
						>
							<IconLoadingSVG className="scale-75" />
						</span>
					)}
					<div
						className={clsx(
							'duration-over transition-opacity ease-out',
							isLoading && 'opacity-30',
						)}
						key="label"
					>
						{keys && keys.length && onClick && (
							<SVKeyboardKey key="keys" keys={keys} onTrigger={onClick} />
						)}
						{/* @ts-expect-error */}
						<Component
							className={clsx(
								'duration-over relative box-border block leading-none transition-colors ease-out',
								use === USES.DEFAULT &&
									'type-label -smp:py-[3px] -smp:px-1 px-2 py-[6px] text-[11px] font-semibold uppercase',
								isDisabled && 'text-gray-700',
								(isCurrent || isPressed) &&
									use !== USES.SUBNAV &&
									use !== USES.FEEDNAV &&
									(isNav ? 'text-primary' : 'text-gray-600'),
								!isDisabled &&
									!isCurrent &&
									!isPressed &&
									use !== USES.SUBNAV &&
									use !== USES.FEEDNAV &&
									(isNav ? 'text-gray-600' : 'text-secondary'),
								isNav ? 'hover:text-gray-200' : 'hover:text-muted',
								use === USES.FEEDNAV && 'type-subnav font-regular cursor-pointer',
								use === USES.FEEDNAV
									? isCurrent
										? 'text-primary'
										: 'text-gray-500'
									: '',
								use === USES.SUBNAV && 'type-subnav font-regular',
								use === USES.SUBNAV && !isCurrent && 'text-gray-500',
								use === USES.SUBNAV && isPressed && 'text-gray-700',
								use === USES.RETRACTABLE_BAR &&
									'type-base font-normal text-gray-300',
							)}
							key="button"
							title={formatTitleWithKeys(title || childrenOnlyText(children), keys)}
							onClick={onClick}
							scroll={Component === SVLink ? false : undefined}
							target={target}
							{...props}
						>
							{children}
						</Component>
					</div>
				</div>
			</li>
		);
	},
);

const SVActionsNavItem = React.forwardRef<
	HTMLLIElement,
	React.PropsWithChildren<{
		to: SVLinkProps['to'];
		matchURL?: SVLinkProps['to'];
		target?: SVLinkProps['target'];
	}>
>(({ to, matchURL, ...props }, forwardedRef) => {
	const router = useRouter();

	return (
		<SVActionsItem
			ref={forwardedRef}
			Component={SVLink}
			to={to}
			isCurrent={
				!!(
					matchLocation(router.asPath, String(to)) ||
					(matchURL && matchLocation(router.asPath, String(matchURL)))
				)
			}
			{...props}
		/>
	);
});

const SVActionsItemToggle = React.forwardRef<HTMLLIElement, ItemProps>(
	({ children, ...props }, forwardedRef) => (
		<SVActionsItem ref={forwardedRef} {...props}>
			<SVToggle
				isPressed={props.isPressed || false}
				isDisabled={props.isDisabled}
				size={SVToggle.SIZES.SMALL}
			>
				{children}
			</SVToggle>
		</SVActionsItem>
	),
);

type Props = React.PropsWithChildren<
	Partial<typeof defaultProps> & {
		className?: string;
	}
>;

const SVActions = ({ use, className, children }: Props) => {
	let newChildren: React.ReactNode[] =
		React.Children.map(children, (child, index) => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, {
					key: `item ${index}`,
					// @ts-expect-error ?
					use,
				});
			}
		}) || [];

	const childrenCount = React.Children.count(children);

	if (
		![USES.SUBNAV, USES.PAGENAV, USES.FEEDNAV, USES.RETRACTABLE_BAR].includes(
			use || USES.DEFAULT,
		)
	) {
		newChildren = newChildren?.map((child, index) => {
			let separator: React.ReactNode = null;

			if (index > 0) {
				separator = (
					<li
						className="bg-separator -smp:my-[2px] mx-0 my-1 w-[1px] shrink-0"
						key="separator"
					/>
				);
			}

			return (
				<React.Fragment key={`item ${index}`}>
					{separator}
					{child}
				</React.Fragment>
			);
		});
	}

	return (
		<ul
			className={twMerge(
				clsx(
					'-smp:min-h-[22px] relative flex min-h-[28px] break-normal [hyphens:initial]',
					(use === USES.SUBNAV || use === USES.PAGENAV || use === USES.FEEDNAV) &&
						'mx-auto w-full justify-between',
					use === USES.SUBNAV && '-smp:gap-x-6 gap-x-8',
					use === USES.FEEDNAV && '-md:gap-x-4 gap-x-8',
					use === USES.PAGENAV && '-smp:gap-x-4 h-auto gap-x-6',
					MAX_WIDTH_CLASSES[
						Math.max(MAX_WIDTH_CLASSES.length, Math.min(1, childrenCount)) - 1
					],
				),
				className,
			)}
		>
			{newChildren}
		</ul>
	);
};

SVActions.defaultProps = defaultProps;

SVActionsItem.defaultProps = defaultProps;

// TODO: onlyUpdateForProps

SVActions.Item = SVActionsItem;
SVActions.ItemToggle = SVActionsItemToggle;
SVActions.NavItem = SVActionsNavItem;

SVActions.USES = USES;

export default SVActions;
