import SVImage from '@pkgs/shared-client/components/SVImage';
import SVPageMeta from '@apps/www/src/www/containers/SVPageMeta';
import SVStripePaymentButtonContainer from '@apps/www/src/www/containers/SVStripePaymentButtonContainer';
import useAuthActiveSubscriptionTiers from '@apps/www/src/www/hooks/useAuthActiveSubscriptionTiers';
import useAuthSubscriptionIsExpired from '@apps/www/src/www/hooks/useAuthSubscriptionIsExpired';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import SVNewTeamModal from '@apps/www/src/www/modals/SVNewTeamModal';
import SVCheckoutSubscriptionOverlay from '@apps/www/src/www/overlays/SVCheckoutSubscriptionOverlay';
import SVBrands from '@pkgs/shared-client/components/SVBrands';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVPageHeader from '@pkgs/shared-client/components/SVPageHeader';
import SVPageMargins, { WIDTHS } from '@pkgs/shared-client/components/SVPageMargins';
import SVPageSubtitle from '@pkgs/shared-client/components/SVPageSubtitle';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import SVToggle from '@pkgs/shared-client/components/SVToggle';
import config from '@pkgs/shared-client/config';
import SVWithBodyClass from '@pkgs/shared-client/containers/SVWithBodyClass';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import SVGIconCheck from '@pkgs/shared-client/img/icon-check-inlined.svg';
import andreSouza from '@pkgs/shared-client/img/upgrade/andresouza.png';
import avatarCard from '@pkgs/shared-client/img/upgrade/avatar-card.png';
import avatar1 from '@pkgs/shared-client/img/upgrade/avatar-cloud/1.png';
import avatar10 from '@pkgs/shared-client/img/upgrade/avatar-cloud/10.png';
import avatar11 from '@pkgs/shared-client/img/upgrade/avatar-cloud/11.png';
import avatar12 from '@pkgs/shared-client/img/upgrade/avatar-cloud/12.png';
import avatar13 from '@pkgs/shared-client/img/upgrade/avatar-cloud/13.png';
import avatar14 from '@pkgs/shared-client/img/upgrade/avatar-cloud/14.png';
import avatar15 from '@pkgs/shared-client/img/upgrade/avatar-cloud/15.png';
import avatar16 from '@pkgs/shared-client/img/upgrade/avatar-cloud/16.png';
import avatar2 from '@pkgs/shared-client/img/upgrade/avatar-cloud/2.png';
import avatar3 from '@pkgs/shared-client/img/upgrade/avatar-cloud/3.png';
import avatar4 from '@pkgs/shared-client/img/upgrade/avatar-cloud/4.png';
import avatar5 from '@pkgs/shared-client/img/upgrade/avatar-cloud/5.png';
import avatar6 from '@pkgs/shared-client/img/upgrade/avatar-cloud/6.png';
import avatar7 from '@pkgs/shared-client/img/upgrade/avatar-cloud/7.png';
import avatar8 from '@pkgs/shared-client/img/upgrade/avatar-cloud/8.png';
import avatar9 from '@pkgs/shared-client/img/upgrade/avatar-cloud/9.png';
import commentCard from '@pkgs/shared-client/img/upgrade/comment-card.png';
import domainCard from '@pkgs/shared-client/img/upgrade/domain-card.png';
import fabioSasso from '@pkgs/shared-client/img/upgrade/fabiosasso.png';
import googleLogo from '@pkgs/shared-client/img/upgrade/google.png';
import heroImage1 from '@pkgs/shared-client/img/upgrade/hero-Image-1.png';
import heroImage2 from '@pkgs/shared-client/img/upgrade/hero-Image-2.png';
import heroImage3 from '@pkgs/shared-client/img/upgrade/hero-Image-3.png';
import heroImage from '@pkgs/shared-client/img/upgrade/hero-Image.png';
import cardImage from '@pkgs/shared-client/img/upgrade/person-card.png';
import raySison from '@pkgs/shared-client/img/upgrade/raysison.png';
import teamFeedCard from '@pkgs/shared-client/img/upgrade/team-feed-card.png';
import templatesCard from '@pkgs/shared-client/img/upgrade/templates-card.png';
import BillingCycle from '@pkgs/shared/enums/BillingCycle';
import PaymentButtonType from '@pkgs/shared/enums/PaymentButtonType';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import appendQueryParamsToURL from '@pkgs/shared/helpers/appendQueryParamsToURL';
import {
	Plan,
	getPlanDiscount,
	getPlanFeatures,
	getPlanFromSubscriptionTierAndBillingCycle,
	getPlanMonthlyPrice,
	getSubscriptionTierLabel,
	getSubscriptionTypeFromTier,
} from '@pkgs/shared/plans';
import clsx from 'clsx';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

gsap.registerPlugin(ScrollTrigger);

export const getUpgradePageTierConfig = (tier: ValueOf<typeof SubscriptionTier>) => {
	const defaultTierConfig = {
		tier,
		name: getSubscriptionTierLabel(tier),
		actionLabel: 'Choose Premium',
		getPriceLabel: (billingCycle: ValueOf<typeof BillingCycle>) =>
			`${
				billingCycle === BillingCycle.YEARLY
					? 'Per month, billed annually'
					: ' billed monthly'
			}`,
		getMonthlyPrice: (billingCycle: ValueOf<typeof BillingCycle>) =>
			'$' +
			getPlanMonthlyPrice(
				getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle),
			),
		getDiscount: (billingCycle: ValueOf<typeof BillingCycle>) => {
			const discount = getPlanDiscount(
				getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle),
			)?.toFixed(2);

			return discount ? `Savings $${discount}` : '';
		},
		getPlan: (billingCycle: ValueOf<typeof BillingCycle>) =>
			getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle),
		getFeatures: (billingCycle: ValueOf<typeof BillingCycle>) =>
			getPlanFeatures(getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle)),
		isCurrent: (plan: Plan | null, isPro: boolean) =>
			Boolean(plan && isPro && plan.tier === tier),
		popular: false,
	};
	switch (tier) {
		case SubscriptionTier.TEAM:
			return {
				...defaultTierConfig,
				subtitle: `Better collaboration up to ${config.payment.maxPaidTeamUsers} users`,
				actionLabel: 'Start your team',
			};
		case SubscriptionTier.SITE_MAKER:
			return {
				...defaultTierConfig,
				subtitle: 'All the Premium features plus create your custom website',
				actionLabel: 'Create your Site',
				popular: true,
			};
		case SubscriptionTier.PRO:
		default:
			return {
				...defaultTierConfig,
				subtitle: "Unlock all our Savee's best features",
			};
	}
};

const tierConfigs = Object.values(SubscriptionTier)
	.filter((tier) => tier !== SubscriptionTier.ENTERPRISE)
	.map((tier) => getUpgradePageTierConfig(tier));

const _PlanButton = ({
	label,
	plan,
	isCurrent,
}: {
	label: string;
	plan: Plan;
	isCurrent: boolean;
}) => {
	const router = useRouter();
	const isLoggedIn = useIsLoggedIn();
	const team = useAuthTeam();

	const handleClick = useEventCallback(() => {
		if (!plan) {
			return;
		}

		// If not logged in, redirect to login page
		if (!isLoggedIn) {
			const next = router.asPath !== '/' ? router.asPath : undefined;
			router.push(appendQueryParamsToURL('/login/', next ? { next } : {}));

			return;
		}

		// If team doesn't exist, open create team modal
		if (getSubscriptionTypeFromTier(plan.tier) === SubscriptionType.TEAM && !team) {
			SVModal.open(SVNewTeamModal);

			return;
		}

		SVModal.open(SVCheckoutSubscriptionOverlay, { plan });
	});

	if (isLoggedIn && isCurrent && plan) {
		return (
			<SVButton
				className="w-full max-w-[186px] rounded-lg"
				size={SVButtonSIZES.DEFAULT}
				isDisabled={true}
			>
				Your current plan
			</SVButton>
		);
	}
	return !plan?.paypalPlanID ? (
		<SVStripePaymentButtonContainer
			id="pay-with-card"
			className="bg-brand hover:bg-brand w-full max-w-[186px] rounded-lg text-white hover:opacity-80"
			buttonType={PaymentButtonType.SUBSCRIBE}
			plan={plan}
			submitLabel={label}
		/>
	) : (
		<SVButton
			onClick={handleClick}
			className="bg-brand hover:bg-brand w-full max-w-[186px] rounded-lg text-white hover:opacity-80"
			size={SVButtonSIZES.DEFAULT}
		>
			{label}
		</SVButton>
	);
};

const _Plans = ({ billingCycle }: { billingCycle: ValueOf<typeof BillingCycle> }) => {
	const activeTiers = useAuthActiveSubscriptionTiers();

	return (
		<>
			<div
				id="upgrade-plans"
				className={clsx(
					'-md:w-full -sm:gap-x-0 -sm:gap-y-6 -md:gap-x-4 -md:grid-cols-2 -sm:grid-cols-1 m-auto mt-0 grid grid-cols-3 gap-y-10 gap-x-7 lg:mt-16',
					' mt-12 max-w-7xl',
				)}
			>
				{tierConfigs.map((tierConfig) => {
					const isCurrent = activeTiers.includes(tierConfig.tier);
					const yearlyPriceLabel = tierConfig.getPriceLabel(billingCycle);

					return (
						<div key={tierConfig.name} className="-sm:w-full flex flex-1 flex-col">
							<div className="text-secondary -sm:py-12 -sm:px-8 relative flex flex-grow flex-col items-center justify-center rounded-xl py-7 px-8 ring-1 ring-gray-900 md:py-12">
								<div
									className={clsx(
										'flex w-full flex-col items-center justify-center',
										!tierConfig.popular && '-sm:-mt-5',
									)}
								>
									<div className="-sm:h-auto mb-6 h-6 w-full">
										{tierConfig.popular ? (
											<div className="flex justify-center">
												<div className="bg-brand rounded-full px-3 py-1 text-white">
													Most Popular
												</div>
											</div>
										) : null}
									</div>
									<div className="flex flex-col items-center justify-center space-y-2">
										<p className="type-subtitle font-semibold ">
											{tierConfig.name}
										</p>
										<div className="type-small h-8 text-center text-gray-500">
											{tierConfig.subtitle}
										</div>
									</div>
								</div>
								<div className="mb-8 mt-7 flex w-full flex-col items-center justify-center space-y-2">
									{billingCycle !== BillingCycle.MONTHLY ? (
										<p className="type-small text-center text-gray-500 line-through">
											{tierConfig.getMonthlyPrice(BillingCycle.MONTHLY)}
										</p>
									) : null}
									<p className="text-center text-5xl font-semibold">
										{tierConfig.getMonthlyPrice(billingCycle)}
									</p>
									{yearlyPriceLabel !== '' ? (
										<p className="type-small text-gray-500">
											{yearlyPriceLabel}
										</p>
									) : null}
								</div>
								<_PlanButton
									label={tierConfig.actionLabel}
									plan={tierConfig.getPlan(billingCycle)}
									isCurrent={isCurrent}
								/>
								<ul className="mt-8 w-full flex-1 flex-col space-y-3">
									{tierConfig
										.getFeatures(BillingCycle.YEARLY)
										.map((feature, i) => (
											<li key={i} className="flex items-center space-x-2">
												<SVGIconCheck className="h-[16px] w-[16px] text-gray-600" />
												<span className="type-small text-gray-400">
													{feature.label}
												</span>
												{feature.soon ? (
													<SVButton
														use={SVButtonUSES.VIOLATOR}
														size={SVButtonSIZES.TINY}
														className="text-primary bg-gray-800 bg-opacity-100 text-[10px] font-semibold opacity-100 ring-0"
														isDisabled={true}
													>
														SOON
													</SVButton>
												) : null}
											</li>
										))}
								</ul>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

const socialProof = [
	{
		avatar: andreSouza,
		name: 'Andre Souza',
		headline: 'Product  Designer @ Apple',
		review:
			'It’s rare to find an idea executed with so much thoughtful restraint. Finding and saving visual ' +
			'inspiration was once polluted with so many distractions, ads and a bloated interface. SAVEE rescued ' +
			'us from all of that. It gave us back simplicity. I love it.',
	},
	{
		avatar: raySison,
		name: 'Ray Sison',
		headline: 'Partner, Design @ Playlist',
		review:
			'Savee not only inspires me but it gives me a great view on where design is going and help me find hidden ' +
			"talent that I won't normally get to see through the traditional channels. " +
			'I never start a project without a little inspiration from Savee.',
	},
	{
		avatar: fabioSasso,
		name: 'Fabio Sasso',
		headline: 'Head of Design @ Google',
		companyLogo: googleLogo,
		review:
			'Savee is a visual feast curated by one of the most talented community of designers, photographers ' +
			'and visual artists. It is hard to not feel deeply inspired by using their service.',
	},
];

const _SocialProof = () => {
	return (
		<div className="flex-center -sm:space-y-4 flex-col space-y-12">
			<div className="-md:flex-col -md:space-x-0 -md:space-y-4 -md:items-center flex flex-row justify-center space-x-4">
				{socialProof.map((proof, i) => (
					<div
						key={i}
						className="top-0 flex h-[256px] max-w-[360px] flex-1 flex-col rounded-2xl p-6 ring-1 ring-gray-900"
					>
						<p className="type-small text-gray-500">{proof.review}</p>
						<SVFlexSpacer />
						<div className="border-px -md:mt-8 flex items-center rounded-2xl">
							<SVImage
								alt={`${proof.name} avatar`}
								loading="lazy"
								src={proof.avatar}
								className="h-14 w-14 rounded-full"
							/>
							<div className="ml-3">
								<p className="type-base text-primary font-semibold">{proof.name}</p>
								<p className="type-small flex items-center justify-center text-gray-400">
									{proof.headline}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const avatarCloud = [
	avatar16,
	avatar1,
	avatar2,
	avatar3,
	avatar4,
	avatar5,
	avatar6,
	avatar7,
	avatar8,
	avatar9,
	avatar10,
	avatar11,
	avatar12,
	avatar13,
	avatar14,
	avatar15,
];

const _AvatarCloud = () => {
	return (
		<div className="flex-center flex-col space-y-12 sm:space-y-8">
			<div className="text-primary text-lg">Trusted by more than 1.2M users</div>
			<div id="avatar-cloud-container" className="flex flex-row justify-center pb-20">
				{avatarCloud.map((avatar, i) => (
					<SVImage
						alt={`avatar${i + 1}`}
						key={i}
						style={{ zIndex: 16 - i }}
						loading="lazy"
						src={avatar}
						className="avatar-entry -sm:w-8 -sm:h-8 -md:w-16 -md:h-16 relative -ml-3 h-[72px] w-[72px]"
					/>
				))}
			</div>
		</div>
	);
};

const _RequestEnterpriseDemo = () => {
	const router = useRouter();

	const handleRequestDemo = useEventCallback(() => {
		router.push('/contact-sales/');
	});

	const _Wrapper = ({
		children,
		className,
	}: {
		children: React.ReactNode;
		className?: string;
	}) => {
		return (
			<div
				className={clsx(
					'-sm:h-[180px] flex h-full w-full rounded-xl ring-1 ring-gray-900',
					className,
				)}
			>
				{children}
			</div>
		);
	};

	const _GroupCardsSmallContent = () => {
		return (
			<div className="w-full flex-col space-y-2 px-5 sm:px-32">
				<div className="flex space-x-2">
					<div className="w-1/2">
						<_Wrapper>
							<div className="h-full w-full p-4 sm:p-8">
								<SVImage
									src={avatarCard}
									alt="avatar card"
									className="h-8 w-auto rounded-xl object-contain text-start sm:h-14"
								/>
								<div className="type-subtitle -smp:mt-4 mt-8 font-semibold text-gray-400">
									Easy <br /> collaboration
								</div>
								<div className="type-small text-gray-500">
									Keep everyone inspired <br /> with Savee.
								</div>
							</div>
						</_Wrapper>
					</div>
					<div className="w-1/2">
						<_Wrapper>
							<div className="h-full w-full">
								<SVImage
									src={cardImage}
									alt="cardImage"
									className="h-full w-full rounded-xl object-cover object-center"
								/>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="flex space-x-2">
					<div className="w-1/2">
						<_Wrapper>
							<div>
								<div className="h-1/3 w-full sm:h-2/3">
									<SVImage
										src={templatesCard}
										alt="templates card"
										className="h-full w-full rounded-t-xl object-cover object-center"
									/>
								</div>
								<div className="h-full w-full p-4 sm:h-1/3 sm:p-8 sm:pt-8">
									<div className="type-subtitle font-semibold text-gray-400">
										Marketplace
									</div>
									<div className="type-small text-gray-500">
										Your team will get free access to download our templates.
									</div>
								</div>
							</div>
						</_Wrapper>
					</div>
					<div className="w-1/2">
						<_Wrapper>
							<div className="h-full w-full p-4 sm:mt-8 sm:p-8">
								<div className="font-semibold text-gray-400">SSO Integration</div>
								<div className="type-small text-gray-500">
									Unlimited downloads from our store.
								</div>
								<SVImage
									src={domainCard}
									alt="domain card"
									className="mt-4 h-2/4 w-auto object-contain text-start"
								/>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="w-full">
					<_Wrapper className="-sm:h-[330px] sm:h-[300px]">
						<div className="h-full w-1/2 p-8">
							<div className="m-auto flex h-full flex-col items-center justify-center">
								<div className="w-full text-start font-semibold text-gray-400">
									Make
									<br />
									Comments
								</div>
								<div className="type-small w-full text-start text-gray-500">
									Tag your team on <br /> saves you like.
								</div>
							</div>
						</div>
						<div className="w-1/2">
							<SVImage
								src={commentCard}
								alt="comment card"
								className="h-full w-full rounded-xl object-fill"
							/>
						</div>
					</_Wrapper>
				</div>
				<div className="w-full">
					<_Wrapper className="-sm:h-[330px] sm:h-[300px]">
						<div className="relative flex w-full flex-col">
							<div className="-mt-24 flex h-full w-full flex-col justify-center px-8">
								<div className="w-full text-center text-3xl font-semibold text-gray-400 ">
									Team Feed
								</div>
								<div className="type-small w-full text-center text-gray-500">
									Create a safe space for your team to share inspiration
								</div>
							</div>
							<div className="absolute bottom-0 h-4/6 w-full">
								<SVImage
									src={teamFeedCard}
									alt="team feed card"
									className="h-full w-full rounded-xl object-contain"
								/>
							</div>
							<div className="flex-center absolute bottom-10 flex w-full space-x-4">
								<div className="type-subtitle font-semibold text-gray-400">you</div>
								<div className="type-subtitle rounded-full py-2 px-4 font-semibold text-gray-400 ring-1 ring-gray-400 ring-opacity-50">
									team
								</div>
							</div>
						</div>
					</_Wrapper>
				</div>
			</div>
		);
	};

	const _GroupCardsLargeContent = () => {
		return (
			<div className="-lg:px-9 -lg:h-[620px] flex h-[600px] w-full max-w-[1600px] justify-center space-x-4 px-28">
				<div className="w-[20%] space-y-4">
					<div className="h-2/5">
						<_Wrapper>
							<div className="flex h-full w-full flex-col p-6">
								<div className="h-1/3 w-full">
									<SVImage
										src={avatarCard}
										alt="avatar card"
										className="h-full w-full rounded-xl object-contain"
									/>
								</div>
								<div className="h-2/3 space-y-2 pt-7">
									<div className="type-subtitle font-semibold text-gray-400">
										Easy <br /> collaboration
									</div>
									<div className="type-small text-gray-500">
										Keep everyone inspired <br /> with Savee.
									</div>
								</div>
							</div>
						</_Wrapper>
					</div>
					<div className="h-3/5">
						<_Wrapper>
							<div className="flex h-full w-full flex-col">
								<div className="h-full max-h-[200px] w-full">
									<SVImage
										src={templatesCard}
										alt="templates card"
										className="h-full w-full rounded-t-xl object-cover object-center"
									/>
								</div>
								<div className="-lg:pt-4 h-1/3 space-y-2 p-6 pt-7">
									<div className="type-subtitle font-semibold text-gray-400">
										Marketplace
									</div>
									<div className="type-small text-gray-500">
										Your team will get free access <br /> to download our
										templates.
									</div>
								</div>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="w-[36%] space-y-4">
					<div className="h-3/5">
						<_Wrapper>
							<div className=" flex h-full w-1/2 flex-col items-center justify-center space-y-2 p-8">
								<div className="type-subtitle w-full font-semibold text-gray-400">
									Make <br /> Comments
								</div>
								<div className="type-small w-full text-gray-500">
									Tag your team on <br /> saves you like.
								</div>
							</div>
							<div className="relative h-full w-1/2">
								<SVImage
									src={commentCard}
									alt="comment card"
									className="absolute right-0 h-full w-full rounded-r-xl object-fill"
								/>
							</div>
						</_Wrapper>
					</div>
					<div className="flex h-2/5 w-full space-x-4">
						<_Wrapper>
							<SVImage
								src={cardImage}
								alt="card"
								className="h-full w-full rounded-xl object-fill"
							/>
						</_Wrapper>
						<_Wrapper>
							<div className="-md:p-4 flex flex-col space-y-10 p-8">
								<div className="flex flex-col space-y-2">
									<div className="type-subtitle font-semibold text-gray-400">
										SSO Integration
									</div>
									<div className="type-small text-gray-600">
										Unlimited downloads <br />
										from our store.
									</div>
								</div>
								<div className="h-full w-full">
									<SVImage
										src={domainCard}
										alt="domain card"
										className="h-full w-full object-contain"
									/>
								</div>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="h-[102.5%] w-2/5">
					<_Wrapper>
						<div className="flex h-full w-full flex-col">
							<div className="w-full space-y-2 px-24 pt-16 text-center">
								<div className="type-subtitle text-4xl2 font-semibold text-gray-400">
									Team Feed
								</div>
								<div className="type-small text-gray-500">
									Create a safe space for your team to share inspiration
								</div>
							</div>
							<div className="relative h-full w-full">
								<SVImage
									src={teamFeedCard}
									alt="team feed card"
									className="-lg:h-[70%] absolute bottom-0 h-[80%] w-full rounded-xl object-contain"
								/>
								<div className="absolute bottom-20 flex w-full items-center justify-center space-x-4">
									<div className="type-subtitle font-semibold text-gray-400 text-opacity-50">
										you
									</div>
									<div className="type-subtitle rounded-full py-2 px-4 font-semibold text-gray-400 ring-1 ring-gray-400 ring-opacity-50">
										team
									</div>
								</div>
							</div>
						</div>
					</_Wrapper>
				</div>
			</div>
		);
	};

	const _GroupCardsContainer = () => {
		const viewportName = useViewportName();
		const isSmallNav =
			viewportName === 'small' ||
			(!viewportName && ViewportKind.MOBILE) ||
			viewportName === 'medium' ||
			viewportName === 'medium-portrait';

		return isSmallNav ? <_GroupCardsSmallContent /> : <_GroupCardsLargeContent />;
	};

	return (
		<div className="flex-center flex w-full flex-col space-y-20">
			<div className="space-y-3 text-center">
				<div className="type-title -sm:type-huge -sm:text-6xl font-semibold text-gray-400">
					Made for <br className="sm:hidden" /> badass teams
				</div>
				<div className="type-pagenav text-gray-500">
					Savee let's you create a feed for the entire company to consume inspiration
					<br />
					and share work in progress with each other.
				</div>
			</div>
			<_GroupCardsContainer />
			<div>
				<SVButton
					className="bg-brand hover:bg-brand mt-4 w-full max-w-[300px] rounded-lg text-white hover:opacity-80"
					onClick={handleRequestDemo}
				>
					Request a enterprise demo
				</SVButton>
			</div>
		</div>
	);
};

const _SVUpgradePage = () => {
	const [billingCycle, setBillingCycle] = useState<ValueOf<typeof BillingCycle>>(
		BillingCycle.YEARLY,
	);
	const hasExpiredSubscription = Boolean(useAuthSubscriptionIsExpired());

	//do animations
	useEffect(() => {
		const elementsToAnimate: NodeListOf<HTMLImageElement> =
			document.querySelectorAll('.hero-parallax');

		elementsToAnimate.forEach((element) => {
			gsap.to(element, {
				scrollTrigger: {
					scrub: true,
					start: element.dataset.start,
					trigger: element.dataset.start ? element : undefined,
				},
				y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
				ease: 'none',
			});
		});
	}, []);

	const meta = {
		title: 'Upgrade to Premium',
	};

	let title;

	if (hasExpiredSubscription) {
		title = 'Restart your subscription';
		meta.title = 'Restart your subscription';
	} else {
		title = (
			<>
				Upgrade <br className="sm:hidden" /> to Premium
			</>
		);
	}

	return (
		<div>
			<SVPageMeta {...meta} />
			<div className="relative overflow-hidden">
				<div className="-sm:hidden">
					<SVImage
						id="update-hero-image-2"
						src={heroImage2}
						alt="ladder leaning against the wall"
						data-speed={0.05}
						className="hero-parallax -md:top-[7vw] -md:w-[12vw] absolute right-0 top-[40px] w-40 transition-all xl:w-[10vw]"
					/>
					<SVImage
						id="update-hero-image"
						src={heroImage1}
						alt="ball of basketball"
						data-speed={0.05}
						className="hero-parallax -md:top-[7vw] -md:w-[12vw] absolute top-[40px] w-40 transition-all xl:w-[10vw]"
					/>
					<SVImage
						id="update-hero-image-1"
						src={heroImage}
						alt="clock pointer"
						data-speed={0.2}
						className="hero-parallax -md:top-[20vw] -md:w-[7vw] absolute top-[13vw] w-28 transition-all"
					/>
					<SVImage
						id="update-hero-image-3"
						src={heroImage3}
						alt="woman template"
						data-speed={0.2}
						className="hero-parallax -md:top-[20vw] -md:w-[7vw] absolute top-[13vw] right-0 w-28 transition-all"
					/>
				</div>
				<SVPageHeader usePadding={true} className="pt-10">
					<SVPageTitle>
						<span className="type-title -sm:type-huge -sm:text-6xl max-w-max text-gray-400">
							{title || <>Upgrade&nbsp;and save&nbsp;more</>}
						</span>
					</SVPageTitle>
					<SVPageSubtitle>
						<span className="type-pagenav">
							Explore a better experience with unlimited <br className="sm:hidden" />
							saving and browsing.
						</span>
					</SVPageSubtitle>
				</SVPageHeader>
				<SVPageMargins width={WIDTHS.WIDE}>
					<div className="-sm:pb-26 -mt-12 flex-col items-center pb-24">
						<div className="flex-center mt-10 flex w-full">
							<SVToggle
								isPressed={billingCycle !== BillingCycle.YEARLY}
								onClick={() =>
									setBillingCycle(
										billingCycle === BillingCycle.YEARLY
											? BillingCycle.MONTHLY
											: BillingCycle.YEARLY,
									)
								}
								use={SVToggle.USES.BILLING_CYCLE}
								size={SVToggle.SIZES.LARGE}
								optionsChildren={
									<div className="flex-center -sm:space-x-12 -md:space-x-14 -ml-1 flex w-full  justify-between space-x-12">
										<div
											className={clsx(
												'text-background',
												billingCycle !== BillingCycle.MONTHLY &&
													'text-primary',
											)}
										>
											Monthly
										</div>
										<div
											className={clsx(
												'text-background',
												billingCycle !== BillingCycle.YEARLY &&
													'text-primary',
											)}
										>
											Annual
										</div>
									</div>
								}
							/>
						</div>
						<_Plans billingCycle={billingCycle} />
					</div>
					<_AvatarCloud />
					<_SocialProof />
					<div className="-md:mt-32 mt-56 w-full items-center justify-center">
						<div className="type-subnav w-full text-center">
							Used by designers from awesome brands
						</div>
						<div>
							<SVBrands.Brands className="max-w-[1235px] text-gray-400" />
						</div>
					</div>
				</SVPageMargins>
				<div className="-md:mt-32 mt-56">
					<_RequestEnterpriseDemo />
				</div>
				<SVPageMargins>
					<SVLandingPageFooter className="-md:mt-24 mt-40" />
				</SVPageMargins>
			</div>
		</div>
	);
};

const SVUpgradePage = SVWithBodyClass('theme-dark')(_SVUpgradePage);

export default SVUpgradePage;
