import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const defaultProps = {
	usePadding: false,
};

export const WIDTHS = {
	DEFAULT: 'default',
	COMPACT: 'compact',
	WIDE: 'wide',
	FULL: 'full',
} as const;

const WIDTHS_VALUES = {
	[WIDTHS.DEFAULT]: 'mx-[var(--page-margin)]',
	[WIDTHS.COMPACT]: 'mx-[calc(var(--page-margin)*2)]',
	[WIDTHS.WIDE]: 'mx-[calc(var(--page-margin)/2)] -sm:mx-[var(--page-margin)]',
	[WIDTHS.FULL]: 'mx-[0]',
};

type Props = React.PropsWithChildren<
	Partial<typeof defaultProps> & {
		usePadding?: boolean;
		className?: string;
		width?: ValueOf<typeof WIDTHS>;
		style?: React.HTMLProps<HTMLDivElement>['style'];
	}
>;

const SVPageMargins = ({
	className,
	width = WIDTHS.DEFAULT,
	usePadding = false,
	style,
	children,
}: Props) => (
	<div
		className={twMerge(
			clsx('flex-grow', usePadding ? 'px-[var(--page-margin)]' : WIDTHS_VALUES[width]),
			className,
		)}
		style={style}
	>
		{children}
	</div>
);

SVPageMargins.defaultProps = defaultProps;

export default SVPageMargins;
