import { twMerge } from 'tailwind-merge';

type Props = React.PropsWithChildren<{
	className?: string;
}>;

const SVPageTitle = ({ className, children }: Props) => (
	<h1 className={twMerge('type-title mb-4 inline-flex justify-center text-center', className)}>
		<span className="-lg:max-w-4xl inline-block">{children}</span>
	</h1>
);

export default SVPageTitle;
