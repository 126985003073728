import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import SVPageMargins from './SVPageMargins';

const defaultProps = {
	usePadding: false,
};

type Props = React.PropsWithChildren<
	Partial<typeof defaultProps> & {
		usePadding?: boolean;
		className?: string;
		style?: React.HTMLProps<HTMLDivElement>['style'];
	}
>;

const SVPageHeader = ({ className, usePadding = false, style, children }: Props) => (
	<SVPageMargins
		className={twMerge(
			clsx(
				'break-words text-center [hyphens:auto]',
				usePadding ? 'pt-8 pb-14 -sm:pb-10' : 'mt-8 mb-14 -sm:mb-10',
			),
			className,
		)}
		usePadding={usePadding}
		style={style}
	>
		{children}
	</SVPageMargins>
);

SVPageHeader.defaultProps = defaultProps;

export default SVPageHeader;
